import React, { useState } from 'react'

import { Spinner } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { SERVER_PAGE_SIZE } from 'app/settings'
import { getUrl } from 'app/UrlService'
import { useObject } from 'data/hooks/objects'
import { useRecords } from 'data/hooks/records'
import { TaskRelatedTo } from 'data/hooks/tasks/types'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { useRecordEditManagerContext } from 'features/records/RecordEditManagerContext'
import { TasksQuickCreatePopover } from 'features/tasks/TasksQuickCreatePopover'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'
import { useLocalStorageState } from 'utils/useLocalStorageState'
import { ensureArray } from 'utils/utils'

import { Box } from 'ui/components/Box'
import {
    ContextMenu,
    ContextMenuCheckbox,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
} from 'ui/components/ContextMenu'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { stopAllPointerPropagation, stopPropagation } from 'ui/helpers/utilities'

type RecentAction = {
    fieldApiName: string
    value: string
}
export function useRecentActions(objectId: string = ''): {
    recentActions: RecentAction[]
    recordAction: (action: RecentAction) => void
} {
    const [recentActions, setRecentActions] = useLocalStorageState<RecentAction[]>(
        `${objectId}_recent_record_actions`,
        { defaultValue: [] }
    )

    return {
        recentActions,
        recordAction: (action: RecentAction) => {
            setRecentActions([
                action,
                ...recentActions
                    .filter((a: RecentAction) => {
                        return a.fieldApiName !== action.fieldApiName || a.value !== action.value
                    })
                    .slice(0, 4),
            ])
        },
    }
}

const EDITABLE_FIELD_TYPES = ['dropdown', 'multi_select', 'lookup', 'multi_lookup']
type RecordCardContextMenuProps = {
    records: RecordDto[]
    object: ObjectDto
    children: React.ReactNode
    fieldIds: string[]
    disabled?: boolean
    isLinkable?: boolean
    onTriggerContextMenu?: (e: React.MouseEvent) => void
    isTriggerDisabled?: boolean
}
export function RecordContextMenu({
    records,
    children,
    object,
    fieldIds,
    disabled,
    isLinkable = true,
    onTriggerContextMenu,
    isTriggerDisabled,
}: RecordCardContextMenuProps) {
    const { selectedStack: stack } = useAppContext()
    const tasksEnabled = useTasksEnabled()

    const { editRecord } = useRecordEditManagerContext()
    const { recentActions } = useRecentActions(object._sid)
    const sortByFieldsOrder = useSortingByFieldsOrder(object)
    const fields = sortByFieldsOrder(object.fields).filter((field) => !field.is_primary)
    const [addTaskTargetElement, setAddTaskTargetElement] = useState<any | null>(null)
    const editFields = fields.filter(
        (f) => !f.is_read_only && fieldIds.includes(f._sid) && EDITABLE_FIELD_TYPES.includes(f.type)
    )
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)

    const openRecord = () => {
        const record = records[0]
        window.open(getUrl(`${object?.url}/view/${record?._sid}`, stack), '_blank')
    }

    const copyLink = async () => {
        const record = records[0]
        await navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}${getUrl(
                `${object?.url}/view/${record?._sid}`,
                stack
            )}`
        )
    }

    const addTask = (e: React.MouseEvent) => {
        const position = { x: e.clientX - 40, y: e.clientY - 35 }
        const virtualElement = {
            getBoundingClientRect() {
                return {
                    top: position.y,
                    left: position.x,
                    width: 1,
                    height: 1,
                    right: position.x + 1,
                    bottom: position.y + 1,
                }
            },
        }

        setAddTaskTargetElement(virtualElement)
    }
    return (
        <>
            {addTaskTargetElement && tasksEnabled && records[0] && (
                <TasksQuickCreatePopover
                    open
                    targetElement={addTaskTargetElement}
                    onClose={() => setAddTaskTargetElement(undefined)}
                    related_to={records[0]?._sid}
                    related_to_type={TaskRelatedTo.Record}
                    related_to_stack={stack?._sid}
                    isVirtualElement
                    showRelatedToText={records[0]._primary}
                    container={referenceElement ?? undefined}
                />
            )}
            {/** This element just lets us get something in the current component tree which
             * we can pass to the task popover above so it knows how to correctly filter
             * outside click events.
             */}
            <div
                ref={setReferenceElement as React.Ref<HTMLDivElement>}
                style={{ display: 'none' }}
            />
            <ContextMenu>
                <ContextMenuTrigger
                    disabled={isTriggerDisabled || editFields.length === 0}
                    asChild
                    onContextMenu={onTriggerContextMenu}
                >
                    {children}
                </ContextMenuTrigger>
                {!disabled && (
                    <ContextMenuContent
                        onKeyDown={stopPropagation}
                        usePortal
                        onPointerDown={stopPropagation}
                        onClick={stopPropagation}
                    >
                        {records.length === 1 && (
                            <>
                                <ContextMenuItem
                                    onClick={openRecord}
                                    icon="ArrowUpRightFromSquare"
                                    disabled={!isLinkable}
                                >
                                    Open in new tab
                                </ContextMenuItem>
                                <ContextMenuItem
                                    onClick={copyLink}
                                    icon="Link"
                                    disabled={!isLinkable}
                                >
                                    Copy link
                                </ContextMenuItem>
                                {tasksEnabled && (
                                    <ContextMenuItem
                                        onClick={addTask}
                                        icon="CalendarCheck"
                                        disabled={!isLinkable}
                                    >
                                        Add task...
                                    </ContextMenuItem>
                                )}

                                <Divider my="m" />
                            </>
                        )}

                        <ContextMenuLabel>
                            <Icon name="Pencil" mr="xs" size="xs" />
                            Update record
                        </ContextMenuLabel>
                        {recentActions?.length > 0 && (
                            <>
                                {recentActions.map((a: RecentAction) => (
                                    <RecentActionItem
                                        key={a.fieldApiName + a.value}
                                        field={fields.find((f) => f.api_name === a.fieldApiName)}
                                        records={records}
                                        value={a.value}
                                        updateRecord={editRecord}
                                        object={object}
                                    />
                                ))}
                            </>
                        )}
                        {editFields.length > 0 && (
                            <>
                                {recentActions?.length > 0 && (
                                    <ContextMenuLabel ml="m">Other</ContextMenuLabel>
                                )}
                                {editFields.map((field) => (
                                    <ContextMenuSub key={field.api_name}>
                                        <ContextMenuSubTrigger>
                                            <Box pl="m">{field.label}</Box>
                                        </ContextMenuSubTrigger>
                                        <ContextMenuSubContent
                                            usePortal
                                            style={{
                                                maxWidth: '300px',
                                                overflowY: 'auto',
                                                maxHeight: '80vh',
                                            }}
                                            {...stopAllPointerPropagation()}
                                        >
                                            {['multi_select', 'dropdown'].includes(field.type) && (
                                                <SelectFieldSubmenu
                                                    field={field}
                                                    records={records}
                                                    object={object}
                                                    updateRecord={editRecord}
                                                />
                                            )}
                                            {['lookup', 'multi_lookup'].includes(field.type) && (
                                                <RecordLinkFieldSubmenu
                                                    field={field}
                                                    records={records}
                                                    object={object}
                                                    updateRecord={editRecord}
                                                />
                                            )}
                                        </ContextMenuSubContent>
                                    </ContextMenuSub>
                                ))}
                            </>
                        )}
                    </ContextMenuContent>
                )}
            </ContextMenu>
        </>
    )
}

type SubmenuProps = {
    field?: FieldDto
    object?: ObjectDto
    records: RecordDto[]
    updateRecord: (
        record: RecordDto,
        patch: Record<string, any>,
        options?: Record<string, any>
    ) => void
}

function RecentActionItem({
    field,
    value,
    records,
    updateRecord,
}: SubmenuProps & { value: string }) {
    const { recordAction } = useRecentActions(field?.object_id ?? '')
    if (!field) return null
    const isRecordLink = field.type === 'lookup' || field.type === 'multi_lookup'

    const handleClick = () => {
        recordAction({ fieldApiName: field.api_name, value })
        updateRecords(records, field, updateRecord, value)
    }
    return (
        <ContextMenuItem onClick={handleClick}>
            <Box ml="m" display="inline" color="textWeaker" fontSize="bodyXs">
                {field.label}
            </Box>
            <Icon name="ArrowRight" size="xs" color="textWeaker" />
            <Box display="inline" fontWeight="bodyBold">
                {isRecordLink ? (
                    <RecordLinkDisplay objectId={field.link_target_object_id!} value={value} />
                ) : (
                    value
                )}
            </Box>
        </ContextMenuItem>
    )
}
function RecordLinkDisplay({ objectId, value }: { objectId: string; value: string }) {
    const { object } = useObject(objectId)
    const fetchOptions = {
        includeFields: ['_primary'],
        pageSize: SERVER_PAGE_SIZE,
        forcePartials: true,
        bypassPreviewAs: true,
        orderBy: { id: object?.fields.find((f) => f.is_primary)?.api_name, direction: 'asc' },
    }

    const { data: { records: serverRecords = [] } = {} } = useRecords({
        objectSid: objectId,
        fetchOptions,
    })

    const record = (serverRecords as RecordDto[]).find((r) => r._sid === value)
    if (!record) return null

    return <>{record?._primary}</>
}
function SelectFieldSubmenu({ field, records, updateRecord }: SubmenuProps) {
    const { recordAction } = useRecentActions(field?.object_id ?? '')
    const isMulti = field?.type === 'multi_select'
    const currentValueStates =
        field?.options?.options?.map((option) => {
            const recordsWithThisValue = records.filter((r) =>
                ensureArray(r[field?.api_name]).includes(option.value)
            )
            return recordsWithThisValue.length === records.length
                ? true
                : recordsWithThisValue.length === 0
                  ? false
                  : 'indeterminate'
        }) ?? []

    const handleUpdateValue = (value: string | undefined, checked: boolean) => {
        if (!field || typeof value === 'undefined') return

        if (checked) recordAction({ fieldApiName: field.api_name, value })
        updateRecords(records, field, updateRecord, value, !checked)
    }

    return (
        <>
            {field?.options?.options?.map((option, idx) => (
                <ContextMenuCheckbox
                    key={field._sid}
                    checked={currentValueStates[idx]}
                    onCheckedChange={(checked) => handleUpdateValue(option.value, checked)}
                    onSelect={(e) => {
                        if (isMulti) e.preventDefault()
                    }}
                >
                    {option.label}
                </ContextMenuCheckbox>
            ))}
        </>
    )
}

const updateRecords = (
    records: RecordDto[],
    field: FieldDto,
    updateRecord: (
        record: RecordDto,
        patch: Record<string, any>,
        options?: Record<string, any>
    ) => void,
    value: string,
    removingValue?: boolean
) => {
    const isMulti = field.type === 'multi_lookup' || field.type === 'multi_select'
    if (!isMulti) {
        for (const record of records) {
            updateRecord(
                record,
                { [field.api_name]: !removingValue ? value : null },
                { includeFields: [] }
            )
        }
    } else {
        for (const record of records) {
            const currentValue = ensureArray(record[field.api_name])
            if (
                (!removingValue && currentValue.includes(value)) ||
                (removingValue && !currentValue.includes(value))
            )
                continue
            const newValue = !removingValue
                ? [...currentValue, value]
                : currentValue.filter((v) => v !== value)
            updateRecord(record, { [field.api_name]: newValue }, { includeFields: [] })
        }
    }
}

function RecordLinkFieldSubmenu({ field, records, updateRecord }: SubmenuProps) {
    const { recordAction } = useRecentActions(field?.object_id ?? '')
    const isMulti = field?.type === 'multi_lookup'
    const { object } = useObject(field?.link_target_object_id)
    const fetchOptions = {
        includeFields: ['_primary'],
        pageSize: SERVER_PAGE_SIZE,
        forcePartials: true,
        bypassPreviewAs: true,
        orderBy: { id: object?.fields.find((f) => f.is_primary)?.api_name, direction: 'asc' },
    }

    const { data: { records: serverRecords = [] } = {}, isFetching: isServerLoading } = useRecords({
        objectSid: field?.link_target_object_id ?? '',
        fetchOptions,
    })

    const referencedRecordCounts = records.reduce(
        (result, record) => {
            if (!field) return result

            const currentValue = ensureArray(record[field.api_name]) as string[]
            for (const value of currentValue) {
                if (result[value] === undefined) result[value] = 0
                result[value]++
            }
            return result
        },
        {} as { [key: string]: number }
    )

    const handleUpdateValue = (value: string, checked: boolean) => {
        if (!field) return

        if (checked) recordAction({ fieldApiName: field.api_name, value })
        updateRecords(records, field, updateRecord, value, !checked)
    }

    const focusFirstItem = (el: HTMLElement | null) => {
        if (el) {
            ;(el.nextSibling?.nextSibling as HTMLElement | null)?.focus()
        }
    }

    return (
        <>
            <Box
                fontSize="bodyXs"
                mx="m"
                mt="xs"
                role="button"
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                    focusFirstItem(e.target as HTMLDivElement)
                }
            >
                Click here and start typing to search
            </Box>
            <Divider my="m" />
            {(serverRecords as RecordDto[]).map((record) => (
                <ContextMenuCheckbox
                    id={record._sid}
                    key={record._sid}
                    checked={
                        referencedRecordCounts[record._sid] === records.length
                            ? true
                            : referencedRecordCounts[record._sid] > 0
                              ? 'indeterminate'
                              : false
                    }
                    onCheckedChange={(checked) => handleUpdateValue(record._sid, checked)}
                    onSelect={(e) => {
                        if (isMulti) e.preventDefault()
                    }}
                >
                    {record._primary}
                </ContextMenuCheckbox>
            ))}
            {isServerLoading && !serverRecords?.length && (
                <Box flex column center p="xl">
                    <Spinner size="sm" opacity={0.6} />
                </Box>
            )}
        </>
    )
}
