import React, { memo, useEffect, useMemo, useRef } from 'react'

import { SystemStyleObject } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { DropdownOption } from 'features/admin/fields/DropdownFieldConfiguration/types'
import {
    getDefaultDropdownFieldOptionColor,
    getDropdownFieldOptionColor,
    getDropdownFieldOptionTextColor,
} from 'utils/fieldUtils'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import { Dropdown, Editable } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import { DisplayTypes } from './attributeDisplayOptions'
import { AttributeValueWrapper } from './AttributeValueWrapper'
import DisplayText from './DisplayText'
import TagList from './TagList'

const ColoredOptionWrapper = styled.div<{ bgColor: string; textColor: string | undefined }>`
    width: fit-content;
    padding: 0.25rem;

    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};

    border-radius: 5px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
`

const ColoredDotWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: baseline;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const ColoredDot = styled.div<{ bgColor: string }>`
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    background-color: ${(props) => props.bgColor};
    margin-right: 8px;
    border-radius: 100%;
`
const TextWrapper = styled.div`
    flex-shrink: 1;
    min-width: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

type ColoredOptionProps = React.PropsWithChildren<{
    option: DropdownOption
    style?: 'normal' | 'dot'
    allowDropdownColors?: boolean
}>

const ColoredOption: React.FC<ColoredOptionProps> = ({
    option,
    style,
    allowDropdownColors,
    children,
}) => {
    const optionColor = allowDropdownColors
        ? getDropdownFieldOptionColor(option)!
        : getDefaultDropdownFieldOptionColor()

    const textColor = allowDropdownColors ? getDropdownFieldOptionTextColor(option)! : undefined

    if (style === 'dot') {
        return (
            <ColoredDotWrapper>
                <ColoredDot bgColor={optionColor} />
                <TextWrapper>{children}</TextWrapper>
            </ColoredDotWrapper>
        )
    }

    return (
        <ColoredOptionWrapper bgColor={optionColor} textColor={textColor}>
            {children}
        </ColoredOptionWrapper>
    )
}

type IconWrapProps = {
    option: DropdownOption
    allowDropdownColors?: boolean
    style: 'normal' | 'dot'
}

const IconWrap: React.FC<IconWrapProps> = ({ option, allowDropdownColors, style }) => {
    return (
        <ColoredOption option={option} style={style} allowDropdownColors={allowDropdownColors}>
            {'icon' in option && option.icon}
            {option.label}
        </ColoredOption>
    )
}

type DropdownAttributeProps = {
    onChange: (value: DropdownOption | DropdownOption[]) => void
    mode?: (typeof modes)[keyof typeof modes]
    layout?: (typeof layouts)[keyof typeof layouts]
    isMulti?: boolean
    hasFocus?: boolean
    inDataGrid?: boolean
    field?: FieldDto
    id?: string
    style?: React.CSSProperties
    placeholder?: string
    children?: string | string[]
    renderOptions?: {
        disableSearch?: boolean
    }
    controlStyle?: SystemStyleObject

    useOverflowList?: boolean
    shrinkTags?: boolean
    noContainer?: boolean
    displayType?: DisplayTypes
    className?: string
    displaySingleValueAsTag?: boolean
    valueContainerStyle?: SystemStyleObject
    displayUnknownValues?: boolean
}

const DropdownAttribute: React.FC<DropdownAttributeProps> = ({
    mode,
    isMulti,
    layout,
    onChange,
    hasFocus,
    inDataGrid,
    renderOptions = {},
    controlStyle = {},
    placeholder,
    field,
    children,
    style,
    id,
    useOverflowList = true,
    shrinkTags,
    noContainer,
    displayType = 'normal',
    className: providedClassName,
    displaySingleValueAsTag,
    valueContainerStyle,
    displayUnknownValues,
}) => {
    const { disableSearch } = renderOptions
    const dropdownRef = useRef()
    if (inDataGrid) {
        // Focus on open for the data grid.
        // @ts-expect-error: Remove when we type the react-select module.
        dropdownRef.current?.select?.inputRef?.focus()
    }
    useEffect(() => {
        if (inDataGrid) {
            // Focus on open for the AG data grid.
            // @ts-expect-error: Remove when we type the react-select module.
            dropdownRef.current?.select?.inputRef?.focus()
        }
    }, [inDataGrid])

    const options: DropdownOption[] = useMemo(
        () => (field?.options?.options as DropdownOption[]) || [],
        [field]
    )

    const isSupportLoginPermitted = useIsSupportLoginPermitted()
    let className = isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK
    if (inDataGrid) {
        className += ' click-outside-ignore ag-custom-component-popup'
    }
    className += providedClassName ? ` ${providedClassName}` : ''

    const trueValue: React.ReactNode = useMemo(() => {
        if (isMulti) {
            if (Array.isArray(children)) {
                const optionsByValue = options.reduce<Map<string, DropdownOption>>(
                    (acc, option) => acc.set(option.value, option),
                    new Map()
                )

                return (children as string[]).reduce<React.ReactElement[]>((acc, curr) => {
                    let option = optionsByValue.get(curr)
                    if (!option && displayUnknownValues) {
                        option = makeUnknownValueOption(curr)
                    }

                    if (option)
                        acc.push(
                            <AttributeValueWrapper displayType={displayType}>
                                <IconWrap
                                    style={displayType === 'pill' ? 'dot' : 'normal'}
                                    option={option}
                                    allowDropdownColors={field?.options?.allow_dropdown_colors}
                                />
                            </AttributeValueWrapper>
                        )

                    return acc
                }, [])
            }

            return children
        }

        let option = options.find((o) => o.value === children)
        if (!option && displayUnknownValues) {
            option = makeUnknownValueOption(children as string)
        }

        if (option) {
            return (
                <AttributeValueWrapper displayType={displayType}>
                    <IconWrap
                        style={displayType === 'pill' ? 'dot' : 'normal'}
                        option={option}
                        allowDropdownColors={field?.options?.allow_dropdown_colors}
                    />
                </AttributeValueWrapper>
            )
        }
    }, [
        isMulti,
        options,
        displayUnknownValues,
        children,
        displayType,
        field?.options?.allow_dropdown_colors,
    ])

    const form = (
        <Dropdown
            ref={dropdownRef}
            style={style}
            margin="none"
            autoFocus={mode === modes.editable || hasFocus}
            options={options}
            onChange={onChange}
            value={children}
            renderValue={(data: DropdownOption) => {
                if (!field?.options?.allow_dropdown_colors || !data.color) {
                    return data.label
                }

                return (
                    <ColoredOption option={data} allowDropdownColors={true}>
                        {data.label}
                    </ColoredOption>
                )
            }}
            isMulti={isMulti}
            id={id}
            maxWidth="100%"
            usePortal
            isSearchable={!disableSearch}
            className={className}
            placeholder={placeholder}
            allowDropdownColors={field?.options?.allow_dropdown_colors}
            defaultMenuIsOpen={inDataGrid || hasFocus}
            controlStyle={{
                background: 'white',
                ...controlStyle,
            }}
            valueContainerProps={valueContainerStyle}
        />
    )

    const display: React.ReactNode = useMemo(() => {
        const inline = layout === layouts.inline

        if (isMulti && trueValue && useOverflowList) {
            return (
                <TagList
                    items={trueValue as React.ReactElement[]}
                    singleLine={inline}
                    useOverflowList={useOverflowList}
                    shrinkTags={shrinkTags}
                    itemStyle={{ padding: 0, background: 'none' }}
                    className={className}
                />
            )
        }

        if (noContainer) return trueValue || '-'

        if (displaySingleValueAsTag) {
            return (
                <TagList
                    items={[trueValue as React.ReactElement]}
                    singleLine={inline}
                    useOverflowList={useOverflowList}
                    shrinkTags={shrinkTags}
                    itemStyle={{ padding: 0, background: 'none' }}
                    className={className}
                />
            )
        }

        return <DisplayText className={className}>{trueValue || '-'}</DisplayText>
    }, [
        isMulti,
        layout,
        shrinkTags,
        trueValue,
        useOverflowList,
        noContainer,
        className,
        displaySingleValueAsTag,
    ])

    if (mode === modes.editable) {
        return (
            <Editable
                input={(props: unknown) =>
                    React.cloneElement(form, {
                        onBlur: () => {
                            // @ts-expect-error: Remove when we type the <Editable /> component.
                            props.end()
                        },
                    })
                }
                display={() => display}
                onChange={onChange}
            />
        )
    }
    if (mode === modes.editing) {
        return <>{form}</>
    }

    return <>{display}</>
}

export default memo(DropdownAttribute)

function makeUnknownValueOption(value: string): DropdownOption {
    return {
        label: value,
        value,
        color: getDefaultDropdownFieldOptionColor(),
    }
}
