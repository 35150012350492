import React from 'react'

import {
    ContextEntryWithPath,
    ContextGroup,
    ContextGroupWithPath,
    ContextItem,
    DynamicContextGroup,
} from 'features/expressions/types'
import { matchesSearchTerms } from 'features/Search/helpers'
import { CommandsProvider } from 'features/tiptap/Commands/CommandsExtension'

import { RenderContextItem } from './RenderContextItem'

function makeProvider(
    id: string,
    items?: ContextEntryWithPath[],
    title?: string
): CommandsProvider<ContextEntryWithPath> {
    return {
        id,
        title,
        initialItems: items,
        getItems: ({ queryTerms }) => {
            return Promise.resolve({
                items: items?.filter((item) => matchesSearchTerms(item.name, queryTerms)) ?? [],
            })
        },
        renderItem: ({ item, queryTerms }) => {
            return <RenderContextItem item={item as ContextItem} queryTerms={queryTerms} />
        },
        onSelect: ({ editor, item, range }) => {
            queueMicrotask(() => {
                if (item.type === 'function') {
                    editor.chain().insertFunction({ id: item.id }, range).run()
                } else {
                    editor
                        .chain()
                        // @ts-ignore
                        .insertToken(
                            {
                                id: item.path,
                                label: item.name,
                                tag: item.isLiteral ? 'literal' : undefined,
                            },
                            range
                        )
                        .run()
                }
            })
        },
    }
}

export function getContextItemsProviders(
    group: ContextGroup | DynamicContextGroup
): CommandsProvider<ContextEntryWithPath>[] {
    const result: CommandsProvider<ContextEntryWithPath>[] = []
    const dynamicGroup = group as DynamicContextGroup
    if (dynamicGroup.isDynamic) {
        result.push({
            ...makeProvider(group.id),
            getItems: async ({ query, queryTerms }) => {
                return {
                    items: (await dynamicGroup.provideItems({ query, queryTerms }))?.map(
                        (item) => ({ ...item, path: `${group.id}.${item.id}` })
                    ),
                }
            },
        })
    } else {
        const staticGroup = group as ContextGroupWithPath
        const rootItems = staticGroup.items.filter((item) => item.type !== 'group')

        if (rootItems.length > 0) {
            result.push(makeProvider(group.id, rootItems))
        }

        result.push(
            ...staticGroup.items
                .filter((item) => item.type === 'group')
                .map((item) =>
                    makeProvider(item.id, (item as ContextGroupWithPath).items, item.name)
                )
        )
    }

    return result
}
