import { getUrl, Urls } from 'app/UrlService'

import { fetchWithAuth as actualFetchWithAuth } from './fetchWithAuth'
import handleErrorResponse from './handleErrorResponse'

/** @deprecated
 *  Use data/utils/fetchWithAuth instead
 * */
export function fetchWithAuth(url, params, options = {}) {
    return actualFetchWithAuth(url, params, options)
}

export const acceptHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export const fetchAndReturn = (url, params, process, onError, options) => {
    if (!process) process = (string) => string.json()
    return actualFetchWithAuth(url, params, options)
        .then((response) => {
            if (response.status >= 400) {
                handleErrorResponse(response.clone())
                return Promise.reject(response.clone())
            }
            let result = {}

            try {
                result = response.json()
            } catch {
                // ?
            }
            return Promise.resolve(result)
        })
        .catch((error) => {
            if (onError) onError(error, { url: url, params: params })
            return Promise.reject(error)
        })
}

export const fetchAndReturnRaw = (url, params, process, options) => {
    if (!process) process = (string) => string.json()
    return actualFetchWithAuth(url, params, options).then((response) => {
        if (response.status >= 400) {
            return Promise.reject(response)
        }
        return response
    })
}

export class StackerAPI {
    preprocessFromLocal = (data) => {
        let out = data
        if (this.transformFromLocal) {
            out = this.transformFromLocal(data)
        }
        return JSON.stringify(out)
    }

    preprocessToLocal = (data) => {
        let received = data
        if (this.transformToLocal) {
            received = Array.isArray(received)
                ? received.map(this.transformToLocal)
                : this.transformToLocal(received)
        }
        return received
    }

    onFetchFailed = (_error, _extra) => {}

    path = ''

    get(id, options) {
        const path = this.path + (id ? `${id}/` : ``)
        return fetchAndReturn(path, {}, this.preprocessToLocal, this.onFetchFailed, {
            bypassPreviewAs: this.bypassPreviewAs,
            ...options,
        })
    }

    post(data, options) {
        const url = buildUrl(
            this.path,
            options && options.cacheBackendOverride
                ? { _cache_backend_override: options.cacheBackendOverride }
                : {}
        )
        return fetchAndReturn(
            url,
            {
                method: 'POST',
                headers: acceptHeaders,
                body: this.preprocessFromLocal(data),
            },
            null,
            null,
            {
                bypassPreviewAs: this.bypassPreviewAs,
                ...options,
            }
        )
    }

    delete(id, queryDict = null, options) {
        return fetchAndReturnRaw(
            buildUrl(`${this.path + id}/`, queryDict),
            {
                method: 'DELETE',
            },
            null,
            {
                bypassPreviewAs: this.bypassPreviewAs,
                ...options,
            }
        )
    }

    patch(id, data, options, headers) {
        const url = buildUrl(
            `${this.path + id}/`,
            options && options.cacheBackendOverride
                ? { _cache_backend_override: options.cacheBackendOverride }
                : {}
        )
        return fetchAndReturn(
            url,
            {
                method: 'PATCH',
                headers: { ...acceptHeaders, ...headers },
                body: this.preprocessFromLocal(data),
            },
            null,
            null,
            {
                bypassPreviewAs: this.bypassPreviewAs,
                ...options,
            }
        )
    }
}

export function buildUrl(
    url,
    parameters,
    ignoreUndefined = false,
    passArraysAsIndividualParams = true
) {
    let qs = ''
    for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            const value = parameters[key]
            if (value === undefined) continue
            if (Array.isArray(value)) {
                if (passArraysAsIndividualParams) {
                    value.forEach((v) => {
                        qs += `${v}&`
                    })
                } else {
                    value.forEach((v) => {
                        qs += `${encodeURIComponent(key)}=${encodeURIComponent(v)}&`
                    })
                }
            } else if (value !== undefined || !ignoreUndefined) {
                qs += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`
            }
        }
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1) // chop off last "&"
        url = url.indexOf('?') > -1 ? `${url}&${qs}` : `${url}?${qs}`
    }

    return url
}

export const formatNumber = (value, mindp = 0, maxdp = 0) => {
    if (!value || isNaN(value)) return value
    return value.toLocaleString('en', {
        minimumFractionDigits: mindp,
        maximumFractionDigits: maxdp,
    })
}

export const formatValue = (field, value) => {
    let symbol
    let currencyValue
    switch (field.type) {
        case 'currency':
            symbol = field.options?.currency_symbol || ''
            value = parseFloat(value)
            if (value === null || value === undefined || Number.isNaN(value)) {
                return null
            }
            return symbol
                ? `${symbol}\u200A${formatNumber(value, 2, 2)}`
                : formatNumber(value, 2, 2)
        case 'percentage':
            value = parseFloat(value)
            if (value === null || value === undefined || Number.isNaN(value)) {
                return null
            }
            return `${formatNumber(value * 100.0, 0, 3)}\u200A%`
        case 'currency_varying':
            if (value === null || value === undefined) {
                return null
            }

            currencyValue = parseFloat(value.value)
            symbol = value.currency_symbol
            if (
                currencyValue === null ||
                currencyValue === undefined ||
                Number.isNaN(currencyValue)
            ) {
                return null
            }
            if (symbol) {
                return `${symbol}\u200A${formatNumber(currencyValue, 2, 2)}`
            } else {
                return formatNumber(currencyValue, 2, 2)
            }
        default:
            return value
    }
}

export const getStackForCurrentUrl = (workspaceAccountId, stacks) => {
    return stacks.find(
        (stack) =>
            stack.account_id === workspaceAccountId &&
            window.location.pathname.startsWith(getUrl(Urls.Root, stack))
    )
}

export function orderAlphabeticallyStacks(stacks, accountId) {
    return stacks
        .filter((x) => x.account_id === accountId)
        .sort((a, b) => {
            // put demo apps at the bottom, in alphabetical order
            if (a?.options?.is_demo && !b?.options?.is_demo) {
                return 1
            } else if (!a?.options?.is_demo && b?.options?.is_demo) {
                return -1
            }

            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        })
}
