import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'

import { getUrl } from 'app/UrlService'
import WithObject from 'data/wrappers/WithObject'
import WithPages from 'data/wrappers/WithPages'
import { withUser } from 'data/wrappers/WithUser'
import { withViews } from 'data/wrappers/WithViews'
import { CogIcon } from 'features/AppSettings/Data/AppSettingsModalDataLayouts/CogIcon'
import { DetailLayoutSettings } from 'features/AppSettings/Data/AppSettingsModalDataLayouts/DetailLayoutSettings'
import { Section } from 'legacy/v1/ui/index'

import { Flex } from 'v2/ui/index'

export class AppSettingsModalDataLayouts extends React.Component {
    state = {}

    render() {
        const { object, onCloseSettingsModal, noPadding } = this.props
        return (
            <Section style={{ width: '100%', marginBottom: 0, padding: noPadding ? 0 : undefined }}>
                <ViewPagesList object={object} onCloseSettingsModal={onCloseSettingsModal} />
            </Section>
        )
    }
}

const InnerViewPagesList = ({ object, userActions, history, views, onCloseSettingsModal }) => {
    const viewDict = useMemo(() => mapKeys(views, (view) => view._sid), [views])

    const getViewName = (view, page) => {
        switch (get(view, 'type')) {
            case 'list':
                return get(view, 'name', page.name)
            case 'create':
                return 'Create'
            case 'detail':
                return 'Detail'
            default:
                return page.name
        }
    }

    const getEditingUrl = (view, object, page) => {
        const type = view.type
        const objectUrl = `${object.url}${page.url}`
        const viewUrl = view.url
        //this logic is unified with getPageFromUrl. It's a tech debt
        // and there are plans to always use view url instead of object url
        return getUrl(type === 'list' ? (viewUrl ?? objectUrl) : objectUrl)
    }

    return (
        <WithPages showFeatureConfigData>
            {({ pages }) => {
                return (
                    <WithObject objectId={object._sid}>
                        {({ object }) => {
                            const pagesToShow = pages
                                .filter((page) => page.object_id === object._sid)
                                .filter((page) =>
                                    (object?.connection_options?.read_only ?? false)
                                        ? page.options.view_type !== 'create'
                                        : true
                                )

                            return pagesToShow.map((page, index) => {
                                const view = get(viewDict, get(page, 'options.view_id')) || ''
                                return (
                                    <Flex column alignItems="flex-start" key={index}>
                                        <Flex
                                            alignItems="center"
                                            justifyContent="space-between"
                                            style={{ width: '100%' }}
                                        >
                                            <b style={{ minWidth: 300, fontSize: '1.1rem' }}>
                                                {getViewName(view, page)}
                                            </b>
                                            {view?.type === 'detail' ? (
                                                <DetailLayoutSettings
                                                    object={object}
                                                    onCloseSettingsModal={onCloseSettingsModal}
                                                    userActions={userActions}
                                                    view={view}
                                                />
                                            ) : (
                                                <CogIcon
                                                    onClick={() => {
                                                        userActions.startEditing()
                                                        history.push(
                                                            getEditingUrl(view, object, page)
                                                        )

                                                        onCloseSettingsModal()
                                                    }}
                                                />
                                            )}
                                        </Flex>
                                        <div style={{ flexBasis: 15 }} />
                                    </Flex>
                                )
                            })
                        }}
                    </WithObject>
                )
            }}
        </WithPages>
    )
}

const ViewPagesList = withUser(withRouter(withViews(InnerViewPagesList)))
