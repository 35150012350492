import { getColorScale } from 'ui/styling/helpers/themeHelpers'

const defaultAppColor = getColorScale('#ea5c0c')

export type ColorTone =
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | '950'

export const colorSchemeNames = [
    'Rose',
    'Pink',
    'Fuchsia',
    'Purple',
    'Violet',
    'Indigo',
    'Blue',
    'Sky',
    'Cyan',
    'Teal',
    'Emerald',
    'Lime',
    'Orange',
    'Theme',
] as const
const _lowerColorSchemeNames = [
    'rose',
    'pink',
    'fuchsia',
    'purple',
    'violet',
    'indigo',
    'blue',
    'sky',
    'cyan',
    'teal',
    'emerald',
    'lime',
    'orange',
    'theme',
] as const

export type ColorScheme = (typeof colorSchemeNames)[number]
export type ColorSchemeLowerCase = (typeof _lowerColorSchemeNames)[number]

export const themeColors: { [key in `${ColorSchemeLowerCase}${ColorTone}`] } = {
    // Rose
    rose50: '#FFF1F2',
    rose100: '#FFE4E6',
    rose200: '#FECDD3',
    rose300: '#FDA4AF',
    rose400: '#FB7185',
    rose500: '#F43F5E',
    rose600: '#E11D48',
    rose700: '#BE123C',
    rose800: '#9F1239',
    rose900: '#881337',
    rose950: '#4C0519',

    // Pink
    pink50: '#FDF2F8',
    pink100: '#FCE7F3',
    pink200: '#FBCFE8',
    pink300: '#F9A8D4',
    pink400: '#F472B6',
    pink500: '#EC4899',
    pink600: '#DB2777',
    pink700: '#BE185D',
    pink800: '#9D174D',
    pink900: '#831843',
    pink950: '#500724',

    // Fuchsia
    fuchsia50: '#FDF4FF',
    fuchsia100: '#FAE8FF',
    fuchsia200: '#F5D0FE',
    fuchsia300: '#F0ABFC',
    fuchsia400: '#E879F9',
    fuchsia500: '#D946EF',
    fuchsia600: '#C026D3',
    fuchsia700: '#A21CAF',
    fuchsia800: '#86198F',
    fuchsia900: '#701A75',
    fuchsia950: '#4A044E',

    // Purple
    purple50: '#FAF5FF',
    purple100: '#F3E8FF',
    purple200: '#E9D5FF',
    purple300: '#D8B4FE',
    purple400: '#C084FC',
    purple500: '#A855F7',
    purple600: '#9333EA',
    purple700: '#7E22CE',
    purple800: '#6B21A8',
    purple900: '#581C87',
    purple950: '#3B0764',

    // Violet
    violet50: '#F5F3FF',
    violet100: '#EDE9FE',
    violet200: '#DDD6FE',
    violet300: '#C4B5FD',
    violet400: '#A78BFA',
    violet500: '#8B5CF6',
    violet600: '#7C3AED',
    violet700: '#6D28D9',
    violet800: '#5B21B6',
    violet900: '#4C1D95',
    violet950: '#2E1065',

    // Indigo
    indigo50: '#EEF2FF',
    indigo100: '#E0E7FF',
    indigo200: '#C7D2FE',
    indigo300: '#A5B4FC',
    indigo400: '#818CF8',
    indigo500: '#6366F1',
    indigo600: '#4F46E5',
    indigo700: '#4338CA',
    indigo800: '#3730A3',
    indigo900: '#312E81',
    indigo950: '#1E1B4B',

    // Blue
    blue50: '#EFF6FF',
    blue100: '#DBEAFE',
    blue200: '#BFDBFE',
    blue300: '#93C5FD',
    blue400: '#60A5FA',
    blue500: '#3B82F6',
    blue600: '#2563EB',
    blue700: '#1D4ED8',
    blue800: '#1E40AF',
    blue900: '#1E3A8A',
    blue950: '#172554',

    // Sky
    sky50: '#F0F9FF',
    sky100: '#E0F1FE',
    sky200: '#BAE4FD',
    sky300: '#7ED0FB',
    sky400: '#39B8F7',
    sky500: '#0F9FE8',
    sky600: '#037CC2',
    sky700: '#0465A0',
    sky800: '#085684',
    sky900: '#0D476D',
    sky950: '#082D49',

    // Cyan
    cyan50: '#ECFDFF',
    cyan100: '#D0F6FD',
    cyan200: '#A6EDFB',
    cyan300: '#69DEF7',
    cyan400: '#25C5EB',
    cyan500: '#09A8D1',
    cyan600: '#0A80A8',
    cyan700: '#106C8E',
    cyan800: '#165774',
    cyan900: '#174962',
    cyan950: '#093043',

    // Teal
    teal50: '#F0FDFA',
    teal100: '#CDFAF1',
    teal200: '#9BF4E4',
    teal300: '#61E7D4',
    teal400: '#31D0BE',
    teal500: '#18B4A5',
    teal600: '#0F857C',
    teal700: '#12736D',
    teal800: '#135C58',
    teal900: '#144D4A',
    teal950: '#052E2E',

    // Emerald
    emerald50: '#ECFDF6',
    emerald100: '#D2F9E7',
    emerald200: '#A8F2D4',
    emerald300: '#70E5BD',
    emerald400: '#37D0A0',
    emerald500: '#13B689',
    emerald600: '#078766',
    emerald700: '#06765C',
    emerald800: '#085D49',
    emerald900: '#074D3E',
    emerald950: '#032B24',

    // Lime
    lime50: '#F5FBEA',
    lime100: '#E7F6D1',
    lime200: '#D1EFA7',
    lime300: '#B2E274',
    lime400: '#94D249',
    lime500: '#76B82A',
    lime600: '#51841B',
    lime700: '#45701B',
    lime800: '#3A591B',
    lime900: '#314C1B',
    lime950: '#18290A',

    // Orange
    orange50: '#FDEDE7',
    orange100: '#FBE4DA',
    orange200: '#FBC9B5',
    orange300: '#FCA07B',
    orange400: '#FB733E',
    orange500: '#E94A0C',
    orange600: '#D6430A',
    orange700: '#BE3A08',
    orange800: '#952C05',
    orange900: '#802604',
    orange950: '#421906',

    theme50: defaultAppColor[50],
    theme100: defaultAppColor[100],
    theme200: defaultAppColor[200],
    theme300: defaultAppColor[300],
    theme400: defaultAppColor[400],
    theme500: defaultAppColor[500],
    theme600: defaultAppColor[600],
    theme700: defaultAppColor[700],
    theme800: defaultAppColor[800],
    theme900: defaultAppColor[900],
    theme950: defaultAppColor[950],
}
export const palette = {
    // Base
    white00: '#FFFFFF',
    transparent: 'transparent',

    // Gray
    gray50: '#f9fafb',
    gray100: '#f3f4f6',
    gray200: '#e5e7eb',
    gray300: '#d1d5db',
    gray400: '#9ca3af',
    gray500: '#6b7280',
    gray600: '#4b5563',
    gray700: '#374151',
    gray800: '#1f2937',
    gray900: '#111827',
    gray950: '#030712',

    // Dark Alpha
    darkAlpha50: '#3741510D',
    darkAlpha100: '#3741511A',
    darkAlpha200: '#37415133',
    darkAlpha300: '#3741514D',
    darkAlpha400: '#37415166',
    darkAlpha500: '#37415180',
    darkAlpha600: '#37415199',
    darkAlpha700: '#1F2937B2',
    darkAlpha800: '#1F2937CC',
    darkAlpha900: '#1F2937E5',
    darkAlpha950: '#1F2937F2',

    // Light Alpha
    lightAlpha50: '#FFFFFF0D',
    lightAlpha100: '#FFFFFF1A',
    lightAlpha200: '#FFFFFF33',
    lightAlpha300: '#FFFFFF4D',
    lightAlpha400: '#FFFFFF66',
    lightAlpha500: '#FFFFFF80',
    lightAlpha600: '#FFFFFF99',
    lightAlpha700: '#FFFFFFB2',
    lightAlpha800: '#FFFFFFCC',
    lightAlpha900: '#FFFFFFE5',
    lightAlpha950: '#FFFFFFF2',

    // Error
    error50: '#FFF1F0',
    error100: '#FFDEDB',
    error200: '#FFC1BD',
    error300: '#FF9C94',
    error400: '#FF6C61',
    error500: '#FF4F42',
    error600: '#ED2415',
    error700: '#C81A0D',
    error800: '#A5190F',
    error900: '#881C14',
    error950: '#4B0904',

    // Warning
    warning50: '#fefce8',
    warning100: '#fef9c3',
    warning200: '#fef08a',
    warning300: '#fde047',
    warning400: '#facc15',
    warning500: '#eab308',
    warning600: '#ca8a04',
    warning700: '#a16207',
    warning800: '#854d0e',
    warning900: '#713f12',
    warning950: '#422006',

    // Success
    success50: '#f0fdf4',
    success100: '#dcfce7',
    success200: '#bbf7d0',
    success300: '#86efac',
    success400: '#4ade80',
    success500: '#22c55e',
    success600: '#16a34a',
    success700: '#15803d',
    success800: '#166534',
    success900: '#14532d',
    success950: '#052e16',

    // Admin
    admin50: '#EEF2FF',
    admin100: '#E0E7FF',
    admin200: '#C7D2FE',
    admin300: '#A5B4FC',
    admin400: '#818CF8',
    admin500: '#6366F1',
    admin600: '#4F46E5',
    admin700: '#4338CA',
    admin800: '#3730A3',
    admin900: '#312E81',
    admin950: '#1E1B4B',

    ...themeColors,
}
