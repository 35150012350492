import { useEffect, useMemo, useState } from 'react'

import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export type StackerLDFlagSet = LDFlagSet & {
    /**
     * This flag is used to enable workflows.
     */
    automations?: boolean
    chartsWidget?: boolean
    contextMenu?: boolean
    csvImporter?: boolean
    externalIdField?: boolean
    favorites?: boolean
    metricsWidget?: boolean
    notes?: boolean
    postHogDisableSessionRecording?: boolean
    recentItems?: boolean
    search?: boolean
    sharedTables?: boolean
    showAppearanceSettings?: boolean
    tasks?: boolean
    excalidraw?: boolean
    legacyTableView?: boolean
    legacyBoardView?: boolean
    legacyCardView?: boolean
    notifications?: boolean
    appUsersV2?: boolean
    permanentLoginLinks?: boolean
    turboDetailView?: boolean
}

export const useLDFlags = (): { flags: StackerLDFlagSet } => {
    const ldFlags = useFlags()
    const ldFlagOverrides: StackerLDFlagSet = useDeepEqualsMemoValue(
        safeParseJson(window.localStorage.getItem('ldFlagOverrides'))
    )

    return useMemo(
        () => ({
            flags: {
                ...ldFlags,
                ...ldFlagOverrides,
            },
        }),
        [ldFlags, ldFlagOverrides]
    )
}

/**
 * This hook comes with a cost: it renders twice, once saying ready is false, and again
 * when the waitForReady promise returns. If you don't need to monitor ready state,
 * use the hook above, as this one will cause double the renders, which could have significant
 * impact if used in a component that is replicated many times across the page
 * */
export const useLDFlagsWithReadyStatus = (): {
    flags: StackerLDFlagSet
    isClientReady: boolean
} => {
    const { flags } = useLDFlags()
    const ldClient = useLDClient()
    const [isClientReady, setIsClientReady] = useState(false)

    useEffect(() => {
        ldClient?.waitUntilReady().then(() => {
            setIsClientReady(true)
        })
    }, [ldClient])
    return useMemo(() => ({ flags, isClientReady }), [isClientReady, flags])
}

const safeParseJson = (jsonStr: string | null) => {
    try {
        return jsonStr ? JSON.parse(jsonStr) : {}
    } catch {
        return {}
    }
}

export default useLDFlags
