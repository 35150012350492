import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { THUMBNAILS_URL } from 'app/settings'
import { AttachmentValue } from 'features/views/attributes/types'
import { preloadImage } from 'features/views/attributes/utils'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateFileName } from 'ui/helpers/utilities'

type UseAttachmentsAttributeDisplayStateProps = {
    value?: AttachmentValue[]
    maxAttachments?: number
    maxItemLength?: number
    isLoading?: boolean
    displayAsImage?: boolean
    showOnlyCount?: boolean
}

type FinalValue = {
    name: string
    url: string
    attributes: Record<string, unknown>
    exceedsLimit?: boolean
}

export function useAttachmentsAttributeDisplayState({
    value,
    maxAttachments,
    maxItemLength,
    isLoading,
    showOnlyCount = false,
}: UseAttachmentsAttributeDisplayStateProps) {
    const memoizedValue = useDeepEqualsMemoValue(value)

    const queryKey = getQueryKey(memoizedValue)
    const { data: finalValue, isLoading: isLoadingThumbnails } = useQuery(queryKey, async () => {
        // This is done asynchronously because we need to manually load thumbnails for each attachment.
        const newValue = await convertAttachmentValuesToAttachments(memoizedValue, maxItemLength)

        return newValue
    })

    const effectiveIsLoading = isLoading || isLoadingThumbnails
    const effectiveValue = effectiveIsLoading ? PLACEHOLDER_VALUE : finalValue

    const valueLength = effectiveValue?.length ?? 0

    const limitedAttachments = useMemo(() => {
        if (showOnlyCount) return []
        if (!maxAttachments || valueLength <= maxAttachments) return effectiveValue

        return effectiveValue?.map((attachment, idx) => {
            if (idx < maxAttachments) {
                return attachment
            }

            return {
                ...attachment,
                exceedsLimit: true,
            }
        })
    }, [showOnlyCount, maxAttachments, valueLength, effectiveValue])

    const overflowingAttachments = useMemo(() => {
        if (showOnlyCount) return effectiveValue
        if (!maxAttachments || valueLength <= maxAttachments) return []

        return effectiveValue?.slice(maxAttachments)
    }, [showOnlyCount, maxAttachments, valueLength, effectiveValue])

    const overflowingAttachmentsCountLabel = formatOverflowLabel(
        overflowingAttachments,
        showOnlyCount
    )

    const [isOverflowPopupOpen, setOverflowPopupOpen] = useState(false)
    const debouncedSetOverflowPopupOpen = useDebounce(setOverflowPopupOpen, 200)

    const isOverflowOpenedByHover = useRef(false)

    const onOverflowPopupOpenChange = useCallback(
        (isOpen: boolean) => {
            if (isOpen) {
                isOverflowOpenedByHover.current = false
            }

            debouncedSetOverflowPopupOpen(isOpen)
        },
        [debouncedSetOverflowPopupOpen]
    )

    const onOverflowLabelMouseEnter = useCallback(() => {
        isOverflowOpenedByHover.current = true
        debouncedSetOverflowPopupOpen(true)
    }, [debouncedSetOverflowPopupOpen])

    const onOverflowLabelMouseLeave = useCallback(() => {
        debouncedSetOverflowPopupOpen(false)
    }, [debouncedSetOverflowPopupOpen])

    const onOverflowLabelCloseAutoFocus = useCallback((e: Event) => {
        // Don't focus on the trigger if we opened the popup by hovering.
        if (isOverflowOpenedByHover.current) {
            e.preventDefault()
        }
    }, [])

    const onOverflowLabelFocus = useCallback(() => {
        debouncedSetOverflowPopupOpen(true)
    }, [debouncedSetOverflowPopupOpen])

    const onOverflowLabelBlur = useCallback(() => {
        debouncedSetOverflowPopupOpen(false)
    }, [debouncedSetOverflowPopupOpen])

    const wrapperRef = useRef<HTMLDivElement>(null)

    const onOverflowLabelClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        const target = e.currentTarget as HTMLElement
        const index = target.getAttribute('data-index')
        if (!index) return

        // Get the target element with the same gallery index.
        const targetEl = wrapperRef.current?.querySelector(
            `a[data-index='${index}']`
        ) as HTMLElement | null
        if (!targetEl) return

        // Open the gallery.
        targetEl.click()
        setOverflowPopupOpen(false)
    }, [])

    const isOverflowing = !!overflowingAttachments?.length

    const columnCount = limitedAttachments?.length ?? 0
    let gridTemplateColumns = `repeat(${columnCount}, minmax(0, max-content))`
    if (!!overflowingAttachmentsCountLabel) {
        gridTemplateColumns = `${gridTemplateColumns} minmax(0, max-content)`
    }

    return useMemo(
        () => ({
            attachments: limitedAttachments,
            isLoading: effectiveIsLoading,
            overflowingAttachmentsCountLabel,
            overflowingAttachments,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            onOverflowLabelClick,
            wrapperRef,
            isOverflowing,
            gridTemplateColumns,
        }),
        [
            limitedAttachments,
            effectiveIsLoading,
            overflowingAttachmentsCountLabel,
            overflowingAttachments,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            onOverflowLabelClick,
            isOverflowing,
            gridTemplateColumns,
        ]
    )
}

async function convertAttachmentValuesToAttachments(
    attachments?: AttachmentValue[],
    maxItemLength?: number
): Promise<FinalValue[]> {
    if (!attachments) return Promise.resolve([])

    const attachmentsToDisplay = attachments ?? []

    attachmentsToDisplay.sort((a, b) => {
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b)
        }

        if (typeof a === 'string') {
            return -1
        }

        if (typeof b === 'string') {
            return 1
        }

        return (a.filename || a.url).localeCompare(b.filename || b.url)
    })

    return Promise.all(
        attachmentsToDisplay.map((attachment, idx) => {
            return convertAttachmentValueToAttachment(attachment, idx, maxItemLength)
        })
    )
}

async function convertAttachmentValueToAttachment(
    attachment: AttachmentValue,
    idx: number,
    maxItemLength?: number
) {
    let name: string = attachment as string
    if (typeof attachment !== 'string') {
        name = attachment.filename || attachment.url
    }

    let originalName = name
    if (maxItemLength) {
        name = truncateFileName(name, maxItemLength)
    }

    const url = typeof attachment === 'string' ? attachment : attachment.url
    const type = typeof attachment === 'string' ? undefined : attachment.type

    const attributes = await getFileAttributes(idx, url, originalName, type)

    return {
        name,
        url,
        attributes,
    }
}

// These are used by lightGallery to display the attachments.
async function getFileAttributes(idx: number, url: string, filename: string, type?: string) {
    const thumbnailUrl = await getThumbnailUrl(url, filename, type)

    const attributes = {
        'data-index': idx,
        'data-download': true,
        'data-download-url': url,
        'data-thumbnail': thumbnailUrl,
    }

    if (isMedia(filename, type)) {
        const mediaType = getMediaType(filename, type)

        attributes['data-video'] = JSON.stringify({
            source: [
                {
                    src: url,
                    type: mediaType,
                },
            ],
            attributes: { preload: false, controls: true, fluid: true },
        })
    } else {
        attributes['data-src'] = url
    }

    if (isEmbed(filename, type)) {
        attributes['data-pdf'] = true
    }

    return attributes
}

const embedFileNameExtensionRegExp = /\.(pdf|html|txt|htm|xml)$/i
const embedFileTypeRegExp = /(pdf|html|xml)$/i

function isEmbed(filename: string, type: string = '') {
    return (
        embedFileNameExtensionRegExp.test(filename.toLowerCase()) || embedFileTypeRegExp.test(type)
    )
}

const mediaFileNameExtensionRegExp = /\.(mp4|mov|ogv|webm|ogg|oga|mp3|wav)$/i
function isMedia(filename: string, type: string = '') {
    if (type?.startsWith('audio') || type?.startsWith('video')) return true

    return mediaFileNameExtensionRegExp.test(filename.toLowerCase())
}

function getMediaType(filename: string, type: string = '') {
    const extension = filename.split('.').pop()?.toLowerCase()

    switch (extension) {
        case 'mp4':
        case 'mov':
            return 'video/mp4'
        case 'ogv':
        case 'ogg':
            return 'video/ogg'
        case 'webm':
            return 'video/webm'
        case 'oga':
            return 'audio/oga'
        case 'mp3':
            return 'audio/mp3'
        case 'wav':
            return 'audio/wav'
    }

    // If the files have a video type set.
    if (type && type !== 'video/quicktime') {
        return type
    }

    return 'video/mp4'
}

const THUMBNAIL_LOAD_TIMEOUT = 5000

async function getThumbnailUrl(url: string, filename: string, type?: string): Promise<string> {
    const thumbnailUrl = `${THUMBNAILS_URL}${encodeURIComponent(url)}`
    const fallbackUrl = getThumbnailFallbackUrl(filename, type)

    // Try to load the thumbnail, and if it fails, fallback to the default thumbnail.
    const thumbnailPromise = async () => {
        const preloadedImg = await preloadImage(thumbnailUrl, fallbackUrl)

        return preloadedImg.src
    }

    // If the thumbnail takes too long to load, fallback to the default thumbnail.
    const timeoutPromise = async () => {
        return new Promise<string>((resolve) => {
            setTimeout(() => {
                resolve(fallbackUrl)
            }, THUMBNAIL_LOAD_TIMEOUT)
        })
    }

    return Promise.race([thumbnailPromise(), timeoutPromise()])
}

const commonFileExtensionRegExp = /\.(pdf|zip|doc|txt|html|xls|ppt)$/i

function getThumbnailFallbackUrl(filename: string, type?: string) {
    const size = 'sm'

    if (commonFileExtensionRegExp.test(filename)) {
        const extension = filename.split('.').slice(-1).pop()
        return `https://img.icons8.com/ios/${size}/000000/${extension}.png`
    } else if (isMedia(filename, type)) {
        return `https://img.icons8.com/ios/${size}/000000/video-file.png`
    }

    return `https://img.icons8.com/ios/${size}/000000/document.png`
}

const PLACEHOLDER_VALUE: FinalValue[] = [
    {
        name: 'placeholder 1',
        url: 'https://stackerhq.com/image1.jpg',
        attributes: {},
    },
    {
        name: 'placeholder 2',
        url: 'https://stackerhq.com/image2.jpg',
        attributes: {},
    },
    {
        name: 'placeholder 3',
        url: 'https://stackerhq.com/image3.jpg',
        attributes: {},
    },
]

function getQueryKey(attachments?: AttachmentValue[]) {
    const attachmentKeys = attachments
        ?.map((attachment) => {
            if (typeof attachment === 'string') {
                return attachment
            }

            return attachment.url
        })
        .sort()
        .join(',')

    return ['board-attachments', attachmentKeys ?? '']
}

function formatOverflowLabel(overflowingOptions?: FinalValue[], showOnlyCount?: boolean) {
    if (showOnlyCount && !!overflowingOptions?.length) {
        return overflowingOptions.length.toString()
    }

    if (!overflowingOptions?.length) {
        return ''
    }

    return `+${overflowingOptions.length}`
}
