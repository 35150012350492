import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { dataConnectionActions, getDataConnectionsMemoized } from 'data/api/dataConnectionApi'

class _WithDataConnections extends React.Component {
    render() {
        const dataConnectionsData = this.props.dataConnections
        if (!dataConnectionsData) return this.props.loading ? this.props.loading : ''
        const dataConnections = Object.values(dataConnectionsData)
        return this.props.children({
            dataConnections,
            dataConnectionsFetched: this.props.fetched,
            onChange: this.props.dataConnectionActions.update,
            createDataConnection: this.props.dataConnectionActions.create,
            removeDataConnection: this.props.dataConnectionActions.remove,
            fetchedDataConnections: this.props.fetched,
            refetchDataConnections: this.props.dataConnectionActions.fetch,
            updateDataConnection: this.props.dataConnectionActions.update,
        })
    }

    constructor(props) {
        super(props)
        if (!props.dataConnections || !props.dataConnections.length)
            props.dataConnectionActions.fetch()
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.dontUpdate === true) {
            if (nextProps.dataConnections.length !== this.props.dataConnections.length) {
                return true
            }
            return false
        }
        return true
    }
}

_WithDataConnections.propTypes = {}

function mapStateToProps(state) {
    return {
        dataConnections: getDataConnectionsMemoized(state),
        fetched: state.dataConnections.fetched,
        dataConnectionsFetched: state.dataConnections.fetched,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dataConnectionActions: bindActionCreators(dataConnectionActions, dispatch),
    }
}

const WithDataConnections = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(_WithDataConnections)
)

export const withDataConnections = (Child) => (props) => (
    <WithDataConnections>{(dcProps) => <Child {...dcProps} {...props} />}</WithDataConnections>
)
