import {
    CellEditRequestEvent,
    CellPosition,
    Column,
    ColumnMovedEvent,
    NavigateToNextCellParams,
    RangeSelectionChangedEvent,
    RowSelectedEvent,
} from 'ag-grid-community'
import {
    GetRowIdParams,
    GridApi,
    IRowNode,
    ProcessCellForExportParams,
    RowDataTransaction,
    ValueGetterParams,
} from 'ag-grid-enterprise'
import removeMarkdown from 'remove-markdown'

import { isMultiLineText } from 'data/utils/fieldDefinitions'
import { isReadOnlyField } from 'features/admin/data-connector/utils'
import isRichTextField from 'utils/isRichTextField'

import { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'

import { ColumnDefinition, RowData } from './types'

export function getFieldDataType(field: FieldDto): string {
    switch (field.type) {
        case 'checkbox':
            return 'boolean'
        case 'date':
        case 'datetime':
            return 'dateString'
        case 'number':
        case 'currency':
        case 'currency_varying':
        case 'percentage':
            return 'number'
        default:
            return 'text'
    }
}

export function isFieldEditable(field: FieldDto): boolean {
    const allowEdit = !isReadOnlyField(field)
    if (!allowEdit) return false

    switch (field.type) {
        case 'string':
        case 'long_text':
        case 'checkbox':
        case 'dropdown':
        case 'multi_select':
        case 'number':
        case 'currency':
        case 'currency_varying':
        case 'percentage':
        case 'lookup':
        case 'multi_lookup':
        case 'file':
        case 'multi_file':
        case 'image':
        case 'date':
        case 'datetime':
        case 'url':
        case 'document':
            return true

        default:
            return false
    }
}

export function getNewRecordPlaceholder(): RowData {
    return {
        _sid: 'new_record',
        isNewRecord: true,
    }
}

export function isNewRecordPlaceholder(data?: Record<string, unknown>): boolean {
    return !!data?.isNewRecord
}

export function getNewRecordButton(): RowData {
    return {
        _sid: 'add_new_record_button',
        isNewRecordButton: true,
    }
}

export function isNewRecordButton(data?: Record<string, unknown>): boolean {
    return !!data?.isNewRecordButton
}

export function isNewRecordRow(data?: Record<string, unknown>): boolean {
    return isNewRecordPlaceholder(data) || isNewRecordButton(data)
}

export async function updateRecordWithCellEvent(
    event: CellEditRequestEvent,
    editRecord: (record: RecordDto, patch: Partial<RecordDto>) => Promise<void>
): Promise<void> {
    const oldData = event.data

    const apiName = event.colDef.field!
    const newValue = event.newValue
    const patch: Partial<RecordDto> = { [apiName]: newValue }

    // Update the grid in a transaction.
    const tx: RowDataTransaction<RecordDto> = {
        update: [{ ...oldData, ...patch }],
    }
    event.api.applyTransaction(tx)

    try {
        await editRecord(oldData, patch)
    } catch {
        // Revert the changes if the request failed.
        event.api.applyTransactionAsync({
            update: [oldData],
            add: [],
            remove: [],
        })
    }
}

export async function rawImportRecordWithCellEvent(
    event: CellEditRequestEvent,
    importRawRecords: (
        updates: { _sid: string | null; data: Record<string, string> }[]
    ) => Promise<void>
): Promise<void> {
    const oldData = event.data

    const apiName = event.colDef.field!
    const newValue = event.newValue
    const patch: Partial<RecordDto> = { [apiName]: newValue }

    await importRawRecords([
        {
            _sid: oldData._sid,
            data: patch,
        },
    ])
}

export async function createRecordFromCellEvent(
    event: CellEditRequestEvent,
    addRecord: (record?: Partial<RecordDto>) => Promise<RecordDto>
): Promise<void> {
    const apiName = event.colDef.field!
    const newValue = event.newValue

    const oldRecord = event.data
    const updatedRecord = { ...oldRecord, [apiName]: newValue }

    // Update the grid in a transaction.
    const tx: RowDataTransaction<RecordDto> = {
        update: [updatedRecord],
    }
    event.api.applyTransaction(tx)

    try {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { _sid, isNewRecord, ...recordPayload } = updatedRecord

        await addRecord(recordPayload)
    } catch {
        // Revert the changes if the request failed.
        event.api.applyTransactionAsync({
            update: [],
            add: [],
            remove: [oldRecord],
        })
    }
}

export function extractPlainTextFromValue(value: unknown, field: FieldDto): string {
    if (isMultiLineText(field.type, field.options)) {
        let newValue: string = ''

        if (field.type === 'long_text' || (field.type === 'string' && isRichTextField(field))) {
            if (typeof value !== 'string') return ''

            newValue = removeMarkdown(value as string)
        } else if (field.type === 'document') {
            newValue = (value as TipTapValue | undefined)?.plainTextContent ?? ''
        }

        return newValue
    }

    return String(value)
}

export function processDataBeforeCopy(params: ProcessCellForExportParams): unknown {
    return params.formatValue(params.value)
}

export function getValueFromParams(params: ValueGetterParams, field: FieldDto): string | undefined {
    const fieldApiName = params.colDef.field
    const record = params.data as RecordDto

    if (!fieldApiName || !record) return undefined

    const value = record[fieldApiName]

    if (field.type === 'checkbox') {
        return value ?? false
    }

    return value
}

export function getFocusedRow(gridApi: GridApi<RowData>): IRowNode<RowData> | null {
    const focusedCell = gridApi.getFocusedCell()
    if (!focusedCell) return null

    const focusedRowIndex = focusedCell.rowIndex
    const focusedRowNode = gridApi.getDisplayedRowAtIndex(focusedRowIndex)

    return focusedRowNode ? focusedRowNode : null
}

export function getRowId(params: GetRowIdParams<RowData>) {
    return params.data._sid
}

export function isRowSelectable(node: IRowNode<RowData>): boolean {
    return !isNewRecordButton(node.data)
}

export function getColumnIdsFromMovedEvent(params: ColumnMovedEvent): string[] {
    if (!params.finished) return []

    const columns = params.api.getColumnDefs() as ColumnDefinition[]
    return columns.reduce<string[]>((agg, col) => {
        if (!col.isFieldColumn) return agg

        agg.push(col.field!)

        return agg
    }, [])
}

export function startEditingNewRecord(gridApi: GridApi<RowData>, goToPrimaryColumn = true) {
    const lastIndex = gridApi.getDisplayedRowCount() - 1

    let targetColumn: Column | undefined
    if (goToPrimaryColumn) {
        const columns = gridApi.getColumns() ?? []
        // The primary column is usually the first one in the grid, after the row selector.
        const primaryColumn = columns[1]
        // @ts-expect-error
        targetColumn = primaryColumn
    } else {
        const focusedCell = gridApi.getFocusedCell()
        if (focusedCell) {
            // Just go down one row.
            // @ts-expect-error
            targetColumn = focusedCell.column
        }
    }

    if (!targetColumn) return

    gridApi.clearRangeSelection()

    // @ts-expect-error
    gridApi.startEditingCell({ colKey: targetColumn, rowIndex: lastIndex })
}

/**
 * Is the element editable or not? Takes into account inputs, textareas and contenteditable elements.
 * @param target
 */
export function isTargetEditable(target: HTMLElement | null): boolean {
    if (target?.tagName === 'INPUT' && (target as HTMLInputElement)?.type !== 'checkbox') {
        return true
    }
    if (target?.tagName === 'TEXTAREA') return true
    if (target?.isContentEditable) return true

    return false
}

export function handleRowSelected(params: RowSelectedEvent) {
    const node = params.node
    if (!node) return

    if (node.isSelected()) {
        // Clear range selection when a row is selected.
        params.api.clearRangeSelection()
    }
}

export function handleRangeSelectionChanged(params: RangeSelectionChangedEvent) {
    if (!params.finished) return false

    const selectedRanges = params.api.getCellRanges()
    if (!selectedRanges || selectedRanges.length < 1) return

    const selectedNodes = params.api.getSelectedNodes()
    if (selectedNodes.length > 0) {
        // Clear selected rows when a range is selected.
        params.api.deselectAll()
    }
}

export function handleNavigateToNextCell(params: NavigateToNextCellParams): CellPosition | null {
    const { nextCellPosition, api } = params

    // If we focus on the column header, clear out range selection.
    if (nextCellPosition?.rowIndex === -1) {
        api.clearRangeSelection()
    }

    return nextCellPosition
}
