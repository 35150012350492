import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'

type UseCardViewEyebrowStateOptions = {
    record: RecordDto
}

export function useCardViewEyebrowState(options: UseCardViewEyebrowStateOptions) {
    const { record } = options

    const { eyebrowField } = useCardViewContext()

    const attribute: CardViewAttribute | undefined = useMemo(() => {
        if (!eyebrowField) return undefined

        return {
            id: eyebrowField._sid,
            title: eyebrowField.label,
            field: eyebrowField,
            rawOptions: {},
            type: 'eyebrow',
        }
    }, [eyebrowField])

    const value = eyebrowField ? (record[eyebrowField?.api_name] ?? undefined) : undefined

    return useMemo(
        () => ({
            attribute,
            value,
        }),
        [attribute, value]
    )
}
