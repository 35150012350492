// @ts-strict-ignore
import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import useConfirmModal from 'app/ConfirmModal'
import getObject from 'data/utils/getObject'
import { canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'

import { Icon, Text, Tooltip } from 'v2/ui'
import { Badge } from 'v2/ui/components/Badge'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const PermissionRuleRow = styled.tr`
    .label {
        font-size: 14px;
    }

    .table-name {
        font-size: 14px;
        font-weight: 600;
    }

    .roles {
        max-width: 300px;
        margin-top: 4px;
    }

    .actions {
        white-space: nowrap;
        text-align: center;
    }

    button {
        margin-left: 6px;
    }

    .checkbox-container {
        width: 60px;
        text-align: center;
    }

    .info-text {
        padding-left: 8px;
        font-size: 12px;
        color: ${V4DesignSystem.colors.gray[500]};
        font-weight: 600;
    }

    .border-underneath {
        border-bottom: 1px solid ${V4DesignSystem.colors.gray[100]};
    }
`

type SummaryIconProps = {
    value: 'all' | 'some' | 'none'
    tooltip: string
}

const SummaryIcon: React.FC<SummaryIconProps> = ({ value, tooltip }) => {
    const colours = {
        all: V4DesignSystem.colors.green[500],
        some: V4DesignSystem.colors.yellow[400],
        none: V4DesignSystem.colors.red[500],
    }
    const icons = {
        all: 'check',
        some: 'minus',
        none: 'x',
    }
    return (
        <Tooltip placement="bottom" label={tooltip} display="inline-block">
            <Icon icon={icons[value]} color={colours[value]}></Icon>
        </Tooltip>
    )
}

const AddPermissionLink = styled.div`
    cursor: pointer;
    font-size: 12px;
    color: ${V4DesignSystem.colors.stacker};
    font-weight: 600;

    .text {
        padding-left: 8px;
    }
`

type ActionCheckboxProps = {
    action: string
    permissionRule: PermissionRuleDTO
}

const ActionCheckbox: React.FC<ActionCheckboxProps> = ({ action, permissionRule }) => {
    const allFields = permissionRule.field_permissions['*'] || {}

    // Header checkboxes should be indeterminate if they are false but there is a checked box in that column
    const hasCheckedField = { read: false, update: false, create: false }

    const recordPermissions = permissionRule.record_permissions
    if (recordPermissions[action] === false) {
        return <SummaryIcon value="none" tooltip={`Cannot ${action} records`}></SummaryIcon>
    }

    const fieldPermissions = permissionRule.field_permissions
    for (const field of Object.keys(fieldPermissions)) {
        for (const permission of Object.keys(fieldPermissions[field])) {
            if (permissionRule.field_permissions[field][permission]) {
                hasCheckedField[permission] = true
            }
        }
    }

    const isChecked = !!allFields[action]
    const isIndeterminate = !allFields[action] && hasCheckedField[action]
    if (isChecked) {
        return <SummaryIcon value="all" tooltip={`Can ${action} all fields`}></SummaryIcon>
    }

    if (isIndeterminate) {
        return <SummaryIcon value="some" tooltip={`Can ${action} some fields`}></SummaryIcon>
    }

    return <SummaryIcon value="none" tooltip={`Cannot ${action} records`}></SummaryIcon>
}

export const AddPermission = ({ action, testId = '' }) => (
    <AddPermissionLink
        role="button"
        onClick={action}
        className="roles"
        data-testid={testId}
        style={{ display: 'block', whiteSpace: 'nowrap' }}
    >
        <Icon icon="add" size="12px" display="inline-block" />
        <span className="text">Add permission</span>
    </AddPermissionLink>
)

const TableTitle = ({ tableNameColumn, tableName, addNewRule }) => {
    const addPermTestId = `add-permissions.${tableName.replace(/\s/g, '').toLowerCase()}`

    return (
        <>
            {tableNameColumn && (
                <td className="table-name">
                    {tableName}
                    {tableName && <AddPermission action={addNewRule} testId={addPermTestId} />}
                </td>
            )}
        </>
    )
}

const PermissionRule = ({
    permissionRule,
    rolesMap,
    openEditModal,
    permissionRuleActions,
    showRole,
    tableName,
    tableNameColumn,
    addNewRule,
    checkRuleError,
}) => {
    const hasError = useMemo(() => {
        return checkRuleError(permissionRule)
    }, [checkRuleError, permissionRule])

    const roleNames = (permissionRule.granted_by_roles ?? [])
        .filter((roleId) => {
            // if role not found in roleMap just discard it
            // this may be true if the admin role is in granted_by_roles, for example
            // but this can be safely ignored as admins always have permissions for everything
            return (
                !!rolesMap[roleId] &&
                // Show roles with no all data access. If role has all data access, only show it if it's associated to the rule.
                showRole(rolesMap[roleId], permissionRule)
            )
        })
        .map((roleId) => {
            return rolesMap[roleId].label
        })

    const recordsFiltered = !!(
        permissionRule.options.filters_unformatted?.length ||
        permissionRule.options.permissionSelected === 'private'
    )

    const { show: showDeleteConfirm } = useConfirmModal({
        message: <Text>Are you sure you want to delete this permission?</Text>,
        onConfirm: async (modal) => {
            permissionRuleActions.remove(permissionRule._sid)
            modal.toggle()
        },
    })

    const object = getObject(permissionRule.object_id)
    const canDelete =
        !!object && canDeleteRecords(object) && permissionRule.record_permissions.delete

    return (
        <PermissionRuleRow>
            <TableTitle
                tableNameColumn={tableNameColumn}
                tableName={tableName}
                addNewRule={addNewRule}
            />

            <td className="label">
                <Text color={V4DesignSystem.colors.gray[800]}>{permissionRule.label}</Text>
                <div className="roles">
                    <Icon
                        icon="userLock"
                        size="12px"
                        color={V4DesignSystem.colors.gray[400]}
                        display="inline-block"
                    />
                    <span className="info-text">
                        {permissionRule.all_roles
                            ? 'Everyone'
                            : roleNames.length === 0
                              ? 'No roles'
                              : roleNames.join(', ')}
                    </span>
                </div>
            </td>
            <td>
                {hasError && (
                    <Icon
                        style={{ marginLeft: '2px' }}
                        icon="warning"
                        size="13px"
                        color="#FF0000"
                    />
                )}
            </td>

            <td>
                {recordsFiltered ? (
                    <Badge color="yellow">Some records</Badge>
                ) : (
                    <Badge color="green">All records</Badge>
                )}
            </td>

            {['read', 'update', 'create'].map((action) => (
                <td className="checkbox-container" key={action}>
                    <ActionCheckbox action={action} permissionRule={permissionRule} />
                </td>
            ))}

            <td className="checkbox-container">
                {canDelete ? (
                    <SummaryIcon value="all" tooltip={`Can delete records`}></SummaryIcon>
                ) : (
                    <SummaryIcon value="none" tooltip={`Cannot delete records`}></SummaryIcon>
                )}
            </td>

            <td className="actions">
                <Icon
                    className="admin-app-settings-permissions-edit-button"
                    icon="edit"
                    display="inline-block"
                    button
                    color={V4DesignSystem.colors.gray[400]}
                    size="14px"
                    width={8}
                    onClick={() => {
                        openEditModal(permissionRule)
                    }}
                />
                <Icon
                    icon="trash"
                    display="inline-block"
                    button
                    color={V4DesignSystem.colors.gray[400]}
                    size="14px"
                    width={8}
                    textAlign="center"
                    onClick={() => {
                        // @ts-expect-error
                        showDeleteConfirm()
                    }}
                    data-testid={`permissions.delete.icon.${tableName
                        .replace(/\s/g, '')
                        .toLowerCase()}`}
                />
            </td>
        </PermissionRuleRow>
    )
}

export const NoPermissionRules = ({ tableName, tableNameColumn, addNewRule }) => {
    const actions = ['read', 'update', 'create', 'delete']

    return (
        <PermissionRuleRow>
            <TableTitle
                tableNameColumn={tableNameColumn}
                tableName={tableName}
                addNewRule={addNewRule}
            />

            <td className="label border-underneath">No permissions</td>
            <td className="error-header"></td>

            <td className="border-underneath">
                <Badge color="red">No records</Badge>
            </td>

            {actions.map((action) => (
                <td key={action} className="checkbox-container border-underneath">
                    <SummaryIcon value="none" tooltip={`Cannot ${action} records`}></SummaryIcon>
                </td>
            ))}

            <td className="actions border-underneath">
                <Icon
                    icon="add"
                    display="inline-block"
                    button
                    color={V4DesignSystem.colors.gray[400]}
                    size="14px"
                    width={8}
                    onClick={() => {
                        addNewRule()
                    }}
                />
            </td>
        </PermissionRuleRow>
    )
}

export default PermissionRule
