import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactFocusLock from 'react-focus-lock'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHistory, useLocation } from 'react-router-dom'

import useLDFlags from 'data/hooks/useLDFlags'
import { useGlobalSearchProvider } from 'features/Search/GlobalSearchProvider'
import { useNavigationSearchProvider } from 'features/Search/NavigationSearchProvider'
import { useRecentItemsSearchProvider } from 'features/Search/RecentItemsSearchProvider'

import Popper from 'v2/ui/components/Popper'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Button } from 'ui/components/Button'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxInput } from 'ui/components/Combobox/ComboboxInput'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { LoadingIndicator } from 'ui/components/LoadingIndicator/LoadingIndicator'
import { stopPropagation } from 'ui/helpers/utilities'

import { GroupTitleStyle, ItemStyle, OmnibarContainerStyle } from './OmnibarStyles.css'

type OmnibarProps = React.PropsWithChildren<{}>

export type OmnibarContextState = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const OmnibarContext = React.createContext({} as OmnibarContextState)
export const useOmnibarContext = () => React.useContext(OmnibarContext)

export function Omnibar({ children }: OmnibarProps) {
    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()
    const isMobile = useIsMobile()
    const history = useHistory()
    const { flags } = useLDFlags()

    useHotkeys(
        'ctrl+k,meta+k',
        () => {
            if (flags.search || flags.recentItems) {
                setIsOpen(true)
            }
        },
        {
            preventDefault: true,
            enableOnContentEditable: true,
            enableOnFormTags: true,
        }
    )

    const virtualElement = React.useMemo(
        () =>
            ({
                getBoundingClientRect() {
                    return DOMRect.fromRect({
                        y: isMobile ? 0 : 100,
                        x: 0,
                        width: window.innerWidth,
                        height: 0,
                    })
                },
            }) as HTMLElement,
        [isMobile]
    )

    useEffect(() => {
        setIsOpen(false)
    }, [location])
    const handleItemSelected = useCallback(
        (item: any, provider: ItemProvider<any>, event?: React.SyntheticEvent) => {
            if (item.url) {
                setIsOpen(false)
                // @ts-expect-error
                if (event?.metaKey || event?.ctrlKey) return window.open(item.url, '_blank')

                setTimeout(() => history.push(item.url), 50)
            }
        },
        [history]
    )

    const navigationSearchProvider = useNavigationSearchProvider({ enabled: flags.search ?? false })
    const globalSearchProvider = useGlobalSearchProvider()
    const recentItemsProvider = useRecentItemsSearchProvider()

    const contextState = useMemo<OmnibarContextState>(
        () => ({ isOpen, setIsOpen }),
        [isOpen, setIsOpen]
    )

    const providers = [
        ...(flags.search ? [navigationSearchProvider, globalSearchProvider] : []),
        ...(flags.recentItems ? [recentItemsProvider] : []),
    ]

    return (
        <OmnibarContext.Provider value={contextState}>
            <>
                {children}
                {isOpen && (
                    <Popper
                        referenceElement={virtualElement}
                        closeOnOuterAction
                        closeOnEscape
                        onClose={() => setIsOpen(false)}
                        placement="bottom"
                    >
                        <ReactFocusLock>
                            <Container flex column border className={OmnibarContainerStyle}>
                                <Box
                                    center
                                    justifyContent="space-between"
                                    mb="m"
                                    display={{ mobile: 'flex', tablet: 'none' }}
                                    p="l"
                                >
                                    <Box fontSize="headlineXs" color="textWeak">
                                        Search
                                    </Box>
                                    <Button
                                        startIcon={{ name: 'X' }}
                                        variant="ghost"
                                        onClick={() => setIsOpen(false)}
                                    />
                                </Box>
                                <SearchBar
                                    height="full"
                                    shrink
                                    onItemSelected={handleItemSelected}
                                    providers={providers}
                                />
                            </Container>
                        </ReactFocusLock>
                    </Popper>
                )}
            </>
        </OmnibarContext.Provider>
    )
}

type SearchBarProps = BoxProps & {
    providers: ItemProvider<any>[]
    onItemSelected?: (item: any, provider: ItemProvider<any>, event?: React.SyntheticEvent) => void
}
function SearchBar({ providers, onItemSelected, ...props }: SearchBarProps) {
    const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
        onItemSelected,
        providers,
        itemToString: () => '',
    })
    const { isLoading, items, queryFailed, collections, showMore } = itemsState
    const noResults = !isLoading && items.length === 0 && comboboxState.inputValue.length > 0
    return (
        <ComboboxContext.Provider value={comboboxState}>
            <Box flex column stretch position="relative" maxHeight="full" {...props}>
                <Box flex center px="2xl" py="xl">
                    <Icon name="Search" color="textWeakest" mr="m" size="l" />
                    <ComboboxInput
                        autoFocus
                        placeholder="find apps and pages"
                        variant="borderless"
                        grow
                        onBlur={stopPropagation}
                        size="l"
                    />
                    {isLoading && <LoadingIndicator />}
                </Box>
                {queryFailed && (
                    <Container variant="error" p="m" m="xs">
                        An error occurred{' '}
                        {noResults ? 'processing your search.' : 'retrieving some search results.'}{' '}
                    </Container>
                )}
                {items?.length > 0 && (
                    <>
                        <Divider />
                        <Box overflowY="auto" shrink maxHeight="full" pb="l">
                            <ComboboxList
                                collections={collections}
                                queryTerms={queryTerms}
                                itemProps={{ className: ItemStyle }}
                                groupTitleProps={{ className: GroupTitleStyle }}
                                showMore={showMore}
                                isLoading={isLoading}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </ComboboxContext.Provider>
    )
}
