import React, { useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { recordApi } from 'data/api/recordApi'
import { useObject } from 'data/hooks/objects'
import { ToolbarButton } from 'features/admin/datagrid/ToolbarButton'
import useTrack from 'utils/useTrack'

import { Box, Modal } from 'v2/ui'
import { SolidDownload } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

type ExportDataToolbarButtonProps = {
    objectId: string
}

export const ExportDataToolbarButton: React.VFC<ExportDataToolbarButtonProps> = ({ objectId }) => {
    const { selectedStack } = useAppContext()
    const { object } = useObject(objectId)
    const { track } = useTrack()

    const [status, setStatus] = useState<'loading' | 'error' | 'success'>('success')

    const sanitize = (str: string) => str.replace(/[^a-z0-9]/gi, '_').toLowerCase()

    const exportData = async () => {
        track('WIP - Frontend - DataGrid - Export - Clicked', {
            table_name: object?.name,
        })
        setStatus('loading')
        if (object) {
            try {
                await recordApi.downloadCsvRecords({
                    object,
                    filename: `${sanitize(selectedStack?.name + '_' + object.name)}.csv`,
                })
                setStatus('success')
            } catch {
                track('WIP - Frontend - DataGrid - Export - Failed', {
                    table_name: object.name,
                })
                setStatus('error')
            }
        }
    }

    return (
        <>
            <ToolbarButton
                svgIcon={<SolidDownload color={colors.userInterface.accent[1000]} />}
                onClick={exportData}
                data-testid="datagrid.export.button"
                isLoading={status === 'loading'}
                isDisabled={status === 'loading'}
            >
                Export
            </ToolbarButton>
            <Modal
                headerVariant="regular"
                noPadding
                isOpen={status === 'error'}
                closeOnEsc
                closeOnOverlayClick
                showCloseButton
                onClose={() => setStatus('success')}
                title="Error while exporting data"
            >
                <Box p={2}>This data could not be exported, try again or contact support.</Box>
            </Modal>
        </>
    )
}
