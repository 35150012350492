import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useActionMenuContext } from 'features/views/ListView/Actions/ActionMenuContext'
import { isFieldEditable } from 'features/views/ListView/Actions/utils'
import { useListViewContext } from 'features/views/ListView/ListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { isSystemAction, SystemAction } from './useSystemActions'

type SystemActionItem = SystemAction & {
    onClick?: () => void | Promise<void>
}

type UseActionMenuContentOptions = {
    record: RecordDto
    actionButtons?: ActionDto[]
    additionalEditFields?: FieldDto[]
}

export function useActionMenuContentState(options: UseActionMenuContentOptions) {
    const { record, actionButtons = [], additionalEditFields } = options
    const { object, stack, visibleFields } = useListViewContext()

    const userItems = actionButtons.filter((action) => !isSystemAction(action._sid))

    const allRecordActionsMap = actionButtons.reduce(
        (acc, action) => {
            acc[action._sid] = action

            return acc
        },
        {} as Record<string, SystemAction | ActionDto>
    )

    const { deleteRecord, createTaskForRecord } = useActionMenuContext()

    const recordDetailUrl = useMemo(() => {
        return getUrl(`${object?.url}/view/${record?._sid}`, stack)
    }, [object?.url, record?._sid, stack])

    const openInNewTab = useCallback(() => {
        window.open(recordDetailUrl, '_blank')
    }, [recordDetailUrl])

    const history = useHistory()
    const openAsFullPage = useCallback(() => {
        history.push(recordDetailUrl)
    }, [history, recordDetailUrl])

    const copyLink = useCallback(async () => {
        return navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}${recordDetailUrl}`
        )
    }, [recordDetailUrl])

    const navigationItems = useMemo(() => {
        const items: SystemActionItem[] = []

        if (!!allRecordActionsMap.maximize) {
            items.push({
                ...allRecordActionsMap.maximize,
                onClick: openAsFullPage,
            })
        }

        if (!!allRecordActionsMap.open_in_new_tab) {
            items.push({
                ...allRecordActionsMap.open_in_new_tab,
                onClick: openInNewTab,
            })
        }

        if (!!allRecordActionsMap.copy_link) {
            items.push({
                ...allRecordActionsMap.copy_link,
                onClick: copyLink,
            })
        }

        return items
    }, [
        allRecordActionsMap.copy_link,
        allRecordActionsMap.maximize,
        allRecordActionsMap.open_in_new_tab,
        copyLink,
        openAsFullPage,
        openInNewTab,
    ])

    const editableFields = useMemo(
        () => mergeEditableFields(visibleFields, additionalEditFields),
        [visibleFields, additionalEditFields]
    )

    const canEditRecord: boolean =
        !!allRecordActionsMap.edit && record._permissions?.may_update && !!editableFields.length

    const createTask = useCallback(() => {
        // Using queueMicrotask to ensure that the modal is opened
        // after the dropdown is closed, otherwise the dropdown
        // focus lock will override the modal focus lock.
        queueMicrotask(() => {
            createTaskForRecord(record._sid)
        })
    }, [createTaskForRecord, record._sid])

    const managementItems = useMemo(() => {
        const items: SystemActionItem[] = []

        if (!!allRecordActionsMap.add_task) {
            items.push({
                ...allRecordActionsMap.add_task,
                onClick: createTask,
            })
        }

        if (!!allRecordActionsMap.add_favorite) {
            items.push({
                ...allRecordActionsMap.add_favorite,
            })
        }

        return items
    }, [allRecordActionsMap.add_favorite, allRecordActionsMap.add_task, createTask])

    const handleDeleteRecord = useCallback(async () => {
        // Using queueMicrotask to ensure that the modal is opened
        // after the dropdown is closed, otherwise the dropdown
        // focus lock will override the modal focus lock.
        queueMicrotask(() => {
            deleteRecord(record._sid)
        })
    }, [deleteRecord, record._sid])

    const destructiveItems = useMemo(() => {
        const items: SystemActionItem[] = []

        const canDeleteRecord = !!allRecordActionsMap.delete && !!record._permissions?.may_delete
        if (canDeleteRecord) {
            items.push({
                ...allRecordActionsMap.delete,
                onClick: handleDeleteRecord,
            })
        }
        return items
    }, [allRecordActionsMap.delete, handleDeleteRecord, record._permissions?.may_delete])

    return useDeepEqualsMemoValue({
        userItems,
        canEditRecord,
        navigationItems,
        managementItems,
        destructiveItems,
        editableFields,
    })
}

function mergeEditableFields(visibleFields: FieldDto[], additionalEditFields: FieldDto[] = []) {
    const fields = new Map<string, FieldDto>()

    for (const field of visibleFields) {
        if (isFieldEditable(field)) {
            fields.set(field.api_name, field)
        }
    }

    for (const field of additionalEditFields) {
        if (isFieldEditable(field)) {
            fields.set(field.api_name, field)
        }
    }

    return Array.from(fields.values())
}
