import React from 'react'

import { orderBy } from 'lodash'

import { useWorkspaceUsers } from 'data/hooks/users/main'
import { UserDisplay } from 'features/users/shared/UserDisplay'

import { Box } from 'ui/components/Box'
import { StandardComponentProps } from 'ui/helpers/styles'

import { useActivityFeedContext } from './ActivityFeedContext'
import { FeedFilter } from './FeedFilter'

import { SelectedFilterItemIcon } from './SelectedFilterItemIcon.css'

type UsersFilterProps = StandardComponentProps & {
    selectedUserIds?: string[]
    setSelectedUserIds: (UserIds: string[]) => void
}

export function UsersFilter({ selectedUserIds, setSelectedUserIds, ...props }: UsersFilterProps) {
    const { users: feedUsers = [] } = useActivityFeedContext()
    const { data: users = [] } = useWorkspaceUsers()

    const allUsers = orderBy(
        [
            ...users,
            ...feedUsers.filter((user) => !(users as UserDto[]).find((u) => u._sid === user._sid)),
        ],
        (user) => user.name || user.email,
        ['asc']
    )

    const effectiveUserIds = !selectedUserIds?.length
        ? (allUsers?.map((user) => user._sid) ?? [])
        : selectedUserIds

    if (!allUsers) return null
    return (
        <FeedFilter
            items={allUsers}
            idField="_sid"
            selectedIds={effectiveUserIds}
            setSelectedIds={setSelectedUserIds}
            renderItem={(user) => <UserDisplay user={user} />}
            renderContent={(items) => (
                <>
                    <Box flex flexDirection="row-reverse" mr="xs">
                        {items
                            .slice(0, 10)
                            .reverse()
                            .map((user, index) => (
                                <UserDisplay
                                    key={user._sid}
                                    user={user}
                                    showName={false}
                                    className={index > 0 ? SelectedFilterItemIcon : undefined}
                                    rounded="pill"
                                    style={{
                                        zIndex: index,
                                    }}
                                />
                            ))}
                    </Box>
                    {items.length === 1
                        ? items[0].name
                        : items.length === allUsers.length
                          ? 'all users'
                          : `${items.length} users`}
                </>
            )}
            {...props}
        />
    )
}

// type SelectedUserIconProps = BoxProps & {
//     User: UserDto
// }

// function SelectedUserIcon({ User, style, ...props }: SelectedUserIconProps) {
//     return (
//         <Box
//             flex
//             key={User._sid}
//             column
//             center
//             width={6}
//             height={6}
//             rounded={3}
//             style={{ ...style, backgroundColor: User.options.theme.brandColor }}
//             flexShrink={0}
//             {...props}
//         >
//             <UserIcon User={User} isSelected theme={{ theme: 'light' }} size="14px" />
//         </Box>
//     )
// }
