import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import PropTypes from 'prop-types'
import queryString from 'qs'

import { AppUserContext, Rights } from 'app/AppUserContext'
import settings from 'app/settings'
import { useAccounts } from 'data/hooks/accounts'
import { buildUrl } from 'data/utils/utils'
import { withUser } from 'data/wrappers/WithUser'
import { ImpersonatingWarningPage } from 'features/auth/ImpersonatingWarningPage'
import { EmailVerificationCheck } from 'features/auth/new-stacker-auth/EmailVerificationCheck'
import { MustLoginToAccessWorkspacePage } from 'features/auth/new-stacker-auth/MustLoginToAccessWorkspace'
import NoAdminRightsWarningPage from 'features/auth/NoAdminRightsWarningPage'
import PrivateRouteAuthLoadingPage from 'features/auth/PrivateRouteAuthLoadingPage'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { getRedirectParam } from 'utils/utils'

import { useAuthContext } from './AuthContext/AuthContext'
import AppContext from './AppContext'
import { getAbsoluteWorkspaceRootUrl, getUrl, trimRootPathFromUrl, Urls } from './UrlService'
import { useWorkspaceContext } from './WorkspaceContext'

const FailedRedirect = () => {
    const { user } = useAuthContext()
    const { workspaceAccount, workspaceZone } = useWorkspaceContext()
    const { data: accounts } = useAccounts()
    const redirectTo = getRedirectParam()

    const userCouldHaveAccessToWorkspace =
        accounts?.some((account) => account._sid === workspaceAccount._sid) ?? false

    const domain = !!workspaceAccount ? getAbsoluteWorkspaceRootUrl(workspaceAccount) : undefined
    // If it's an SSO account (and we're on the internal zone) and we're not on the correct domain for the workspace
    // (SSO accounts must be on a non-studio domain), then redirect to it now.
    if (
        workspaceAccount &&
        workspaceAccount.sso_required &&
        workspaceZone?.type === 'Internal' &&
        window.location.origin !== domain
    ) {
        const path = window.location.pathname.replace(`/${workspaceAccount.slug}`, '')
        window.location.assign(`${domain}${path}`)
        return null

        // If we don't have a workspace account and we don't have a user, just redirect to login
    } else if (!workspaceAccount || !user) {
        return <Redirect to={buildUrl(Urls.Login, { r: redirectTo })} />

        // If the current user could have access to the workspace if they reauthed, then show the reauth page
    } else if (userCouldHaveAccessToWorkspace) {
        return <MustLoginToAccessWorkspacePage workspaceAccount={workspaceAccount} />

        // Otherwise, the user is trying to navigate to a valid workspace, but they don't
        // have any access to it, so just redirect them home which will put them into their default workspace
    } else {
        return <Redirect to={Urls.Root} />
    }
}

const PrivateRoute = ({
    component: Component,
    redirectTo,
    emailVerificationRequired = true,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                const component = (
                    <PrivateComponent
                        component={Component}
                        redirectTo={redirectTo}
                        loginRedirect={() => {
                            return <FailedRedirect />
                        }}
                        permitAuthToken
                        {...props}
                    />
                )

                if (emailVerificationRequired) {
                    return <EmailVerificationCheck>{component}</EmailVerificationCheck>
                }
                return component
            }}
        />
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    redirectTo: PropTypes.string,
}

PrivateRoute.defaultProps = {
    component: undefined,
    redirectTo: null,
}

export default PrivateRoute

export const StackerDomainPrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <EmailVerificationCheck>
                        <PrivateComponent
                            component={Component}
                            loginRedirect={() => {
                                return (
                                    <FailedRedirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: props.location },
                                        }}
                                    />
                                )
                            }}
                            permitAuthToken={false}
                            {...props}
                        />
                    </EmailVerificationCheck>
                )
            }}
        />
    )
}

StackerDomainPrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    location: PropTypes.string.isRequired,
}

// A functional component wrapper to be able to use context hooks
const RenderPrivatePageIfUserHasAdminRights = ({ Component, ...rest }) => {
    const { selectedStack } = useContext(AppContext)
    const { user, role, hasRight } = useContext(AppUserContext)

    // if there is no currently selected app,
    // no need to check admin rights -- just show the page
    if (!selectedStack) {
        return <Component {...rest} />
    }

    // if there is no user or role in state,
    // wait until they load before doing permissions check, just displaying a loading page in the meantime
    //
    // if we don't wait, hasRight will return false if either are undefined
    // and we'll get a flash of the <NoAdminRightsWarningPage> before they load in
    if (!user || !role) {
        return <PrivateRouteAuthLoadingPage />
    }

    // otherwise, check the user has admin rights for the current app
    // if not, show a warning
    if (!hasRight(Rights.Admin.All)) {
        return <NoAdminRightsWarningPage />
    }

    // if they do, show the page
    return <Component {...rest} />
}

function InnerPrivateComponent(props) {
    const { isPreviewingAsUserOrRole } = usePreviewServiceContext()
    const { user } = useAuthContext()
    const { workspaceAccount, workspaceUser } = useWorkspaceContext()

    const searchString = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    const ADMIN_WORKSPACE_WHITELIST = [Urls.AdminSupportLogin]

    const route = trimRootPathFromUrl(window.location.pathname)

    if (
        route.startsWith(Urls.AdminHome) &&
        workspaceAccount &&
        !localStorage.getItem('support_login') &&
        !ADMIN_WORKSPACE_WHITELIST.includes(route) &&
        settings.IS_PROD
    ) {
        window.location.assign(getUrl(Urls.Home))
        return null
    }

    // We are authenticated if we have a user, and we either aren't under a workspace account yet
    // or we also have a workspaceUser.
    const isAuthenticated = user && (!workspaceAccount || workspaceUser)

    if (isAuthenticated) {
        if (searchString.redirect) {
            window.location.pathname = '/'
            return <div />
        } else if (props.redirectTo || searchString.r) {
            return <Redirect to={props.redirectTo || searchString.r} {...props} />
        } else if (isPreviewingAsUserOrRole) {
            // If we're previewing as a user or role, then show the impersonation page
            return <ImpersonatingWarningPage />
        }

        return <RenderPrivatePageIfUserHasAdminRights Component={props.component} {...props} />
    } else {
        return props.loginRedirect()
    }
}

InnerPrivateComponent.propTypes = {
    component: PropTypes.elementType.isRequired,
    loginRedirect: PropTypes.func.isRequired,
    permitAuthToken: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string,
    userActions: PropTypes.object.isRequired, // From withUser
}

InnerPrivateComponent.defaultProps = {
    redirectTo: null,
}

const PrivateComponent = withUser(InnerPrivateComponent)
