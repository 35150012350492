import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { useListHeaderContext } from 'features/views/ListView/ListHeader/ListHeaderContext'

import { IconNameVariantType } from 'ui/components/Icon/Icon'

type LabelledField = {
    field: FieldDto
    label: string
    icon?: IconNameVariantType
}

export function useAddNewAdvancedFilterState() {
    const { availableInlineFilterFields, view } = useListHeaderContext()

    const columnsByFieldSid = view.options.columns.reduce(
        (acc, column) => {
            acc[column.fieldId] = column
            return acc
        },
        {} as Record<string, ListViewColumnConfig>
    )

    const labelledFields: LabelledField[] = availableInlineFilterFields.map((field) => {
        const column = columnsByFieldSid[field._sid]
        const label = column?.label || field.label
        const fieldIcon = getFieldIcon(field)

        return {
            field,
            label,
            icon: fieldIcon?.type === 'lucide' ? fieldIcon : undefined,
        }
    })

    return useMemo(
        () => ({
            fields: labelledFields,
        }),
        [labelledFields]
    )
}
