import settings from 'app/settings'
import { fetchWithAuth } from 'data/utils/utils'

/**
 *
 * @param {*} currentBaseUrl Current URL
 * @param {*} subdomain Suggested URL by user
 * @returns
 */

export async function validateSubdomain(
    currentBaseUrl: string,
    subdomain: string
): Promise<string | boolean> {
    const url = `account/check-name?name=${encodeURIComponent(subdomain?.toLowerCase())}`
    const potentialBaseUrl = `${subdomain}.${settings.WORKSPACE_DOMAIN}`.toLowerCase()

    // No changes
    if (potentialBaseUrl.toLowerCase() === currentBaseUrl.toLowerCase()) {
        return true
    }

    try {
        const resp = await fetchWithAuth(url)

        if (resp.status >= 400) {
            return `"${subdomain}.${settings.WORKSPACE_DOMAIN}" is reserved.`
        }

        // backend will return a different base_url if this one is already taken
        // so we check if we got back the same url we requested
        const { base_url, invalid_characters, slug: zoneSlug } = await resp.json()

        if (base_url !== potentialBaseUrl && invalid_characters.length > 0) {
            return `Only letters and numbers are allowed, ${invalid_characters
                .map((c: string) => `"${c}"`)
                .join(' ')} are invalid.`
        }
        if (base_url !== potentialBaseUrl) {
            return `"${subdomain}" is already taken.`
        }

        // We will also be using the slug for the internal zone
        // so we also want to check that there is no conflict there
        if (subdomain != zoneSlug) {
            return `"${subdomain}" is already taken.`
        }

        return true
    } catch {
        return false
    }
}
