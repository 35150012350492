import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import shortid from 'shortid'
import BlockTree from 'v2/blocks/BlockTree'
import { generateAllFields } from 'v2/blocks/blockTypes/view/FieldContainerEditor/common'
import getFieldPerms from 'v2/views/utils/getFieldPerms'
import getImageUrl from 'v2/views/utils/getImageUrl'

import useLDFlags from 'data/hooks/useLDFlags'
import { makeQuickLinksDummyAttributes } from 'features/blank-pages/dummyContent'
import Editor from 'features/pages/editor/Editor'
import fieldToIcon from 'features/utils/fieldToIcon'
import Card from 'legacy/v1/ui/components/Card'
import stackerTheme from 'legacy/v1/ui/styleHelpers/stackerTheme'

import * as SVGIcons from 'v2/ui/svgs'

import { DISPLAY_TYPES } from './List/DisplayTypes'

const CardListImage = ({ object, context, config }) => {
    if (!object || !context || !config) return ''

    let fieldApiName
    if (config.listImage && config.listImage.id) {
        fieldApiName = config.listImage.id
    } else {
        // if no image is set, we don't show anything
        return ''
    }

    const imageRecord = get(context.record, fieldApiName)
    const listImageUrl = getImageUrl(imageRecord)

    // We won't display anything if there is no URL
    // but we can't easily check that the image is an image (and not a pdf or something else)
    if (!listImageUrl) return ''

    const deviceType = window.innerWidth > 768 ? 'desktop' : 'mobile'

    const size = config && config.fitListImage ? 'contain' : 'cover'
    return (
        <Card
            style={{
                backgroundImage: `url(${listImageUrl})`,
                height: deviceType === 'desktop' ? '400px' : '250px',
                backgroundSize: size,
                backgroundColor: 'white',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }}
        />
    )
}

const InnerFieldLayoutEditor = ({
    tree,
    context,
    onChange,
    object,
    config,
    showControls,
    isCreate,
    isFieldDisabled,
    recordPermissions,
    hideFields,
    newCreateForm,
    hideAddPaneTitle = false,
    viewConfig = {},
    viewType = null,
    treeIndex = undefined,
    determineIsBlockDisabled,
    renderBlockDropZone,
    showBlockSelector,
}) => {
    const { flags } = useLDFlags()

    const newContext = useMemo(
        () => ({
            ...context,
            isFieldDisabled,
            hide_block_labels: true,
            viewConfig,
            viewType,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isFieldDisabled, context, viewConfig]
    )

    const blocks = useMemo(
        () => getBlocks(object, hideFields, newCreateForm, viewType, flags),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [object, hideFields, flags]
    )

    // Get list of fields so we can inspect its connection_options later on (read_only)
    const fields = context?.object?.fields ?? []

    const clonedTree = cloneDeep(tree)

    // We might want to filter some tree fields based on permissions
    const filteredTree = filterFields(
        clonedTree || getEmptyTree(),
        recordPermissions,
        object?._permissions,
        isCreate,
        fields
    )

    return (
        <EditorScrollHack>
            <Editor
                tree={filteredTree}
                context={newContext}
                page={{ _sid: null }}
                onChange={onChange}
                blocks={blocks}
                isEditing={showControls}
                frameless
                showBlockSelector={showBlockSelector}
                hideRightSidebar
                hideHeader
                hideFooter
                noEditButton
                menuTheme={stackerTheme}
                treeIndex={treeIndex}
                updatePage={({ blocks }) => onChange(null, blocks, 'is full tree')}
                hideAddPaneTitle={hideAddPaneTitle}
            >
                {({ context, tree }) => {
                    return (
                        <>
                            <div className={`device-${context.deviceType}`}>
                                {config && config.display === 'list' && (
                                    <CardListImage
                                        object={object}
                                        context={context}
                                        config={config}
                                    />
                                )}
                                <BlockTree
                                    isOuter
                                    tree={treeIndex >= 0 ? [tree[treeIndex]] : tree}
                                    context={context}
                                    determineIsBlockDisabled={determineIsBlockDisabled}
                                    renderBlockDropZone={renderBlockDropZone}
                                />
                            </div>
                        </>
                    )
                }}
            </Editor>
        </EditorScrollHack>
    )
}

export const FieldLayoutEditor = React.memo(InnerFieldLayoutEditor)

const EditorScrollHack = styled.div`
    max-width: 100%;

    & > div {
        max-height: unset;
    }
`

// Recursively filter child blocks to set permissions for all fields,
// including nested containers and fields not inside a container.
const filterChildFields = (
    tree,
    allowedFieldNamesToCreate,
    allowedFieldNamesToUpdate,
    allowedFieldNamesToRead,
    isCreate,
    fields
) => {
    // Tree contents: page -> center_content -> gridcard_x/attribute_x
    const blocks = tree.childBlocks

    if (!blocks) return tree // If there are no child nodes, nothing needs to be filtered. Just return the tree.

    // Loop through every field and recursively call function to reach every container
    for (let i = 0; i < blocks.length; i++) {
        blocks[i] = filterChildFields(
            blocks[i],
            allowedFieldNamesToCreate,
            allowedFieldNamesToUpdate,
            allowedFieldNamesToRead,
            isCreate,
            fields
        )
    }
    // This removes certain fields, and modifies field containers. All other block types are left as they are.
    const filteredChildren = blocks
        .filter((block) => {
            // Ignore non-fields

            if (block.type !== 'attribute') return true
            // Get the apiName from the sid
            const { fieldId } = block.config.attributes
            if (!fieldId) return true
            const apiName = fieldId.split('.').slice(-1)[0]

            // Only display permitted fields.
            return (
                allowedFieldNamesToRead.includes(apiName) ||
                (isCreate && allowedFieldNamesToCreate.includes(apiName))
            )
        })
        .map((block) => {
            // Ignre non-fields or field-containers
            if (block.type === 'attribute') {
                return modifyAttributeBlockForRendering(
                    block,
                    allowedFieldNamesToCreate,
                    allowedFieldNamesToUpdate,
                    allowedFieldNamesToRead,
                    isCreate
                )
            } else if (block.type === 'field_container') {
                return modifyFieldContainerBlockForRendering(
                    block,
                    allowedFieldNamesToCreate,
                    allowedFieldNamesToUpdate,
                    allowedFieldNamesToRead,
                    isCreate,
                    fields
                )
            } else {
                return block
            }
        })
    tree.childBlocks = filteredChildren
    return tree
}

const modifyAttributeBlockForRendering = (
    block,
    allowedFieldNamesToCreate,
    allowedFieldNamesToUpdate,
    allowedFieldNamesToRead,
    isCreate
) => {
    // Set whether this field is read-only or not and return it.
    const fieldId = get(block, 'config.attributes.fieldId')
    if (!fieldId) return block
    const apiName = block.config.attributes.fieldId.split('.').slice(-1)[0]
    let readOnly = false

    if (isCreate) {
        // Set read-only field if create==false on create view
        readOnly = !allowedFieldNamesToCreate.includes(apiName)
    } else {
        // Set read-only fields if update==false on detail view
        readOnly = !allowedFieldNamesToUpdate.includes(apiName)
    }

    block.config.attributes.readOnly = readOnly
    return block
}

const modifyFieldContainerBlockForRendering = (
    block,
    allowedFieldNamesToCreate,
    allowedFieldNamesToUpdate,
    allowedFieldNamesToRead,
    isCreate,
    fields
) => {
    // Filter the fields in this block for whether they are readable, read-only or read-write and return it.
    const configuredItems = get(block, 'config.attributes.contents')
    const showAllFields = get(block, 'config.attributes.showAllFields')

    // If we're in all fields mode, then we need to generate the list of fields \
    // rather than using the preconfigured list.
    const containerItems = showAllFields
        ? generateAllFields(fields, configuredItems, isCreate)
        : configuredItems

    if (!containerItems) return block

    const filteredItems = containerItems
        .filter((item) => {
            // Ignore non-field items (section headers, etc.)
            if (item.type !== 'field') return true

            // If an item is a non-readable field, then remove it from the container.
            return (
                allowedFieldNamesToRead.includes(item.fieldName) ||
                (isCreate && allowedFieldNamesToCreate.includes(item.fieldName))
            )
        })
        .map((item) => {
            // Ignore non-field items (section headers, etc.)
            if (item.type !== 'field') return item

            // If the field is not writable, then mark it as read-only.
            const field = fields.find((f) => f.api_name === item.fieldName)
            let readOnly = get(field, 'connection_options.read_only')
            // We set readOnly flag on import time (airtable only)
            if (!readOnly) {
                if (isCreate) {
                    readOnly = !allowedFieldNamesToCreate.includes(item.fieldName)
                } else {
                    readOnly = !allowedFieldNamesToUpdate.includes(item.fieldName)
                }
            }
            item.readOnly = readOnly
            return item
        })

    block.config.attributes.contents = filteredItems
    return block
}

const filterFields = (tree, recordPermissions, objectPermissions, isCreate, fields) => {
    // Show or hide fields according to whether they are allowed by permissions
    const [allowedFieldNamesToRead, allowedFieldNamesToUpdate, allowedFieldNamesToCreate] =
        getFieldPerms(recordPermissions, objectPermissions)

    if (!tree || !tree.length) {
        return []
    }

    for (let i = 0; i < tree.length; i++) {
        // Call the recursive function to filter fields to any depth.
        tree[i] = filterChildFields(
            tree[i],
            allowedFieldNamesToCreate,
            allowedFieldNamesToUpdate,
            allowedFieldNamesToRead,
            isCreate,
            fields
        )
    }
    return tree
}

// We expect this never to be used, but it is more helpful than returning `[]` because this at least leaves a view stub which can be edited in the editor.
const getEmptyTree = () => {
    return [
        {
            id: 'page',
            type: 'container',
            config: {
                locked: true,
                style: {
                    default: {
                        width: '100%',
                        height: '100%',
                        margin: '0px auto',
                        flexGrow: 0,
                        justifyContent: 'flex-start',
                    },
                },
                broadcast: [],
                attributes: {
                    label: 'Page',
                    direction: 'column',
                },
            },
            childBlocks: [
                {
                    id: 'center_content',
                    type: 'container',
                    config: {
                        locked: true,
                        style: {
                            default: {
                                width: '100%',
                                flexGrow: '0',
                                marginTop: '20px',
                                minHeight: '50px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '0px',
                            },
                        },
                        broadcast: [],
                        attributes: {
                            label: 'Center Content',
                            direction: 'column',
                        },
                    },
                    childBlocks: [
                        {
                            id: 'gridcard_0',
                            type: 'gridcard',
                            config: {
                                locked: true,
                                style: {
                                    default: {
                                        width: '100%',
                                        minHeight: '100',
                                        boxShadow: 'none',
                                        borderRadius: '5px',
                                        marginBottom: '10px',
                                    },
                                },
                                broadcast: [],
                                attributes: {
                                    label: 'Content card',
                                },
                            },

                            childBlocks: [],
                        },
                    ],
                },
            ],
        },
    ]
}

const WIDGETS_BUILDERS = {
    highlights: (object) => {
        return {
            id: 'template_field_highlights',
            icon: <SVGIcons.Highlights />,
            label: 'Highlights',
            description: 'Highlight certain fields',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    objectId: object?._sid,
                    contents: [],
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    pipeline: (object) => {
        return {
            id: 'template_pipeline',
            icon: <SVGIcons.Pipeline />,
            label: 'Pipeline',
            description: 'Display a pipeline with stages',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    fieldId: null,
                    readOnly: false,
                    objectId: object?._sid,
                    stages: [],
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    text: () => {
        return {
            id: 'template_text',
            icon: <SVGIcons.TextBlock />,
            label: 'Text',
            description: 'Display text content',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    content: '',
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    callout: () => {
        return {
            id: 'template_callout',
            icon: <SVGIcons.Callout />,
            label: 'Callout',
            description: 'Display a callout with title and subtitle',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    title: '',
                    subtitle: '',
                    style: 'form',
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    quicklinks: () => {
        return {
            id: 'template_quicklinks',
            icon: <SVGIcons.QuickLinks />,
            label: 'Quick Links',
            description: 'Display useful links',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    items: [],
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    tasks: () => {
        return {
            id: 'template_tasks',
            icon: <SVGIcons.Tasks />,
            label: 'Task List',
            description: 'Display tasks linked to the record',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: { title: 'Tasks' },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    table: () => {
        const display = 'table'
        const { title } = DISPLAY_TYPES[display]
        return {
            id: 'template_table',
            icon: <SVGIcons.Table />,
            label: title,
            description: 'Display dynamic data in a table',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    display,
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    cards: () => {
        const display = 'card'
        const { title } = DISPLAY_TYPES[display]
        return {
            id: 'template_card',
            icon: <SVGIcons.Cards />,
            label: title,
            description: 'Display dynamic data as cards',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    display,
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    rows: () => {
        const display = 'rows'
        const { title } = DISPLAY_TYPES[display]
        return {
            id: 'template_rows',
            icon: <SVGIcons.Rows />,
            label: title,
            description: 'Display dynamic data as rows',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    display,
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    relatedRecord: (object) => {
        return {
            id: 'template_related_record',
            icon: <SVGIcons.RelatedRecord />,
            label: 'Related record',
            description: 'Display a single related record',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    fieldId: null,
                    content: [],
                    label: null,
                    columns: 3,
                    actionButtons: [],
                    objectId: object?._sid,
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    relatedList: (object) => {
        return {
            id: 'template_detail_view_related_list',
            icon: <SVGIcons.List />,
            label: 'Related record list',
            description: 'List data related to this record',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    objectId: object?._sid,
                },
                style: {
                    default: {
                        width: '100%',
                        boxShadow: 'none',
                        borderRadius: '5px',
                    },
                },
            },
        }
    },
    embed: (object) => {
        return {
            id: 'template_iframe',
            icon: <SVGIcons.Embed />,
            label: 'Embed',
            description: 'Display a link in an embedded iframe',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    objectId: object?._sid,
                    title: '',
                    height: '300px',
                },
                style: {
                    default: {
                        width: '100%',
                        boxShadow: 'none',
                        borderRadius: '5px',
                    },
                },
            },
        }
    },
    metrics: () => {
        return {
            id: 'template_metrics',
            icon: <SVGIcons.SolidMetrics />,
            label: 'Big numbers',
            description: 'Display summaries of your data',
            blockElement: 'FAKE',
            isNew: true,
            defaultConfig: {
                attributes: {
                    metrics: [],
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    charts: () => {
        return {
            id: 'template_charts',
            icon: <SVGIcons.WidgetCharts />,
            label: 'Charts',
            isNew: true,
            description: 'Display a row of charts',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    metrics: [],
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
    fields: (object) => {
        return {
            id: 'template_field_container',
            icon: <SVGIcons.Fields />,
            label: 'Fields',
            description: 'Display any combination of fields',
            blockElement: 'FAKE',
            defaultConfig: {
                attributes: {
                    objectId: object?._sid,
                    contents: [{ type: 'section', id: shortid.generate() }],
                },
                style: {
                    default: {
                        width: '100%',
                    },
                },
            },
        }
    },
}

const getBlocks = (object, hideFields, newCreateForm, viewType, flags) => {
    const embedBlock = WIDGETS_BUILDERS.embed(object)
    if (viewType === 'blankpage') embedBlock.defaultConfig.attributes.type = 'url'

    const quickLinksBlock = WIDGETS_BUILDERS.quicklinks()

    quickLinksBlock.defaultConfig.attributes = makeQuickLinksDummyAttributes()

    const staticWidgets = [
        WIDGETS_BUILDERS.text(object),
        embedBlock,
        WIDGETS_BUILDERS.callout(),
        quickLinksBlock,
    ]

    if (viewType === 'blankpage') {
        const dataWidgets = [
            ...(flags?.chartsWidget ? [WIDGETS_BUILDERS.charts()] : []),
            ...(flags?.metricsWidget ? [WIDGETS_BUILDERS.metrics()] : []),
            WIDGETS_BUILDERS.table(),
            WIDGETS_BUILDERS.cards(),
            WIDGETS_BUILDERS.rows(),
            ...(flags?.tasks ? [WIDGETS_BUILDERS.tasks()] : []),
        ]

        return [
            {
                viewType: 'blankpage',
                name: 'Static widgets',
                icon: 'fa-paragraph',
                blocks: [...staticWidgets],
            },
            {
                viewType: 'blankpage',
                name: 'Data widgets',
                icon: 'table',
                blocks: [...dataWidgets],
            },
        ]
    }

    let blocks = [
        {
            blocks: [],
        },
    ]

    const widgets = [
        ...(flags?.chartsWidget ? [WIDGETS_BUILDERS.charts()] : []),
        ...(flags?.metricsWidget ? [WIDGETS_BUILDERS.metrics()] : []),
        WIDGETS_BUILDERS.fields(object),
        WIDGETS_BUILDERS.highlights(object),
        WIDGETS_BUILDERS.pipeline(object),
        WIDGETS_BUILDERS.relatedRecord(object),
        WIDGETS_BUILDERS.relatedList(object),
        ...(flags?.tasks ? [WIDGETS_BUILDERS.tasks(object)] : []),
    ]

    const createViewWidgets = [
        WIDGETS_BUILDERS.fields(object),
        WIDGETS_BUILDERS.text(object),
        WIDGETS_BUILDERS.callout(object),
    ]

    if (!newCreateForm) {
        blocks[0].blocks = [...widgets, ...staticWidgets]
    } else {
        blocks[0].blocks = [...createViewWidgets]
    }

    if (!hideFields && !newCreateForm) {
        blocks.push({
            name: 'Fields',

            blocks: object?.fields
                .filter(
                    (field) => !(field.connection_options && field.connection_options.is_disabled)
                )
                .map((field) => {
                    return {
                        id: `template_${field.api_name}`,
                        ...fieldToIcon(field),
                        label: field.label,
                        blockElement: 'FAKE',
                        blockTemplate: {
                            type: 'attribute',
                            config: {
                                attributes: {
                                    objectId: object?._sid,
                                    fieldId: field._sid,
                                    fullWidth: field.type === 'long_text',
                                },
                                style: { default: { width: '100%' } },
                            },
                        },
                    }
                }),
        })
    }

    return blocks
}
