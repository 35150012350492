// @ts-strict-ignore
import React from 'react'

import {
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    TabList,
    TabPanels,
} from '@chakra-ui/react'
import get from 'lodash/get'
import FieldsEditor from 'v2/blocks/blockTypes/view/FieldContainerEditor/FieldsEditor'
import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { useObject } from 'data/hooks/objects'
import { useGetRecord } from 'data/hooks/records'
import WithObject, { withObject } from 'data/wrappers/WithObject'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import { useProcessFilter } from 'features/records/components/RecordFilters'

import {
    Box,
    ConfigureWidgetPlaceholder,
    Container,
    Dropdown,
    FixedGrid,
    Flex,
    Input,
    Tab,
    Tabs,
    Text,
} from 'v2/ui'
import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'
import { RecordLinkDisplay } from 'v2/ui/components/Attribute/RecordLinkDisplay'
import TabPanel from 'v2/ui/components/TabPanel'
import * as SVGIcons from 'v2/ui/svgs'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { ActionButtonsList } from './FieldContainerBlock'

const getLookupFieldOptionsFromObject = ({ fields }) => {
    if (!fields) return []

    return fields
        .filter((field) => {
            // Note: a 'lookup' field in this context is a link
            // Foreign fields are not suitable for related record widget so are filtered out
            return (
                field.type === 'lookup' &&
                !field.connection_options?.is_disabled &&
                !field.is_foreign
            )
        })
        .map((record) => ({
            label: record.label,
            value: record._sid,
        }))
}

// Here, 'object' is the object whose layout you are editing (or viewing)
const RelatedRecordEditor = withObject(({ object, params, setAttr }) => {
    const field = object.fields.find((f) => f._sid === params.fieldId)
    const { object: relatedObject } = useObject(field?.link_target_object_id)

    return (
        <Tabs variant="admin" d="flex" flexDirection="column" height="100%">
            <TabList>
                <Tab variant="admin" style={{ width: '50%' }}>
                    Content
                </Tab>
                <Tab variant="admin" style={{ width: '50%' }} disabled={!params.fieldId}>
                    Actions
                </Tab>
            </TabList>

            <TabPanels mt={4} height="100%" minHeight={0}>
                <TabPanel height="100%">
                    <Flex flexDirection="column" alignItems="stretch" height="100%">
                        <Text variant="paletteSectionLabel">Field</Text>
                        <Box mb={3}>
                            <Dropdown
                                value={params.fieldId}
                                options={getLookupFieldOptionsFromObject(object)}
                                placeholder="Select a record link field..."
                                onChange={(fieldId) => {
                                    setAttr('fieldId', fieldId)
                                }}
                            />
                        </Box>
                        {!params.fieldId && (
                            <Text
                                size="sm"
                                fontStyle="italic"
                                color={V4DesignSystem.colors.gray[600]}
                            >
                                <b>Related record</b> requires a record link field with only a{' '}
                                single value. To display the contents of a record link field with
                                multiple values, use the <b>related record list</b>
                            </Text>
                        )}

                        {params.fieldId && field && (
                            <>
                                <Text variant="paletteSectionLabel">Label</Text>
                                <Input
                                    variant="admin"
                                    id="label"
                                    value={params.label}
                                    placeholder={field.label}
                                    onChange={(event) => {
                                        setAttr('label', event.target.value)
                                    }}
                                    mb={3}
                                />

                                <Text variant="paletteSectionLabel">Columns</Text>
                                <Box pl={2} pr={6}>
                                    <Slider
                                        flex="1"
                                        min={1}
                                        max={4}
                                        value={params.columns}
                                        onChange={(value) => setAttr('columns', value)}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb fontSize="sm" width="32px" height="20px">
                                            {params.columns}
                                        </SliderThumb>
                                    </Slider>
                                </Box>

                                <Box height="100px" flexGrow={1} overflow="hidden" mb={2}>
                                    {relatedObject && (
                                        <FieldsEditor
                                            object={relatedObject}
                                            items={params.content}
                                            setItems={(newValue) => {
                                                setAttr('content', newValue)
                                            }}
                                            disallowSections={true}
                                            hideFieldRequiredSetting={true}
                                            hideFieldDescriptionSetting={true}
                                            hideDisabledSearchSetting={true}
                                            hideCreateButtonSetting={true}
                                            isLayoutEditorComponent
                                            style={{
                                                height: '100%',
                                                marginBottom: '0',
                                                marginTop: '0',
                                            }}
                                            showConditionalVisibility
                                            conditionalVisibilityFilters={
                                                params.fieldConditionalVisibilityFilters
                                            }
                                            onConditionalVisibilityFiltersChange={(filtersMap) =>
                                                setAttr(
                                                    'fieldConditionalVisibilityFilters',
                                                    filtersMap
                                                )
                                            }
                                        />
                                    )}
                                </Box>
                            </>
                        )}
                    </Flex>
                </TabPanel>

                <TabPanel height="100%">
                    {field && (
                        <WithObject objectId={field.link_target_object_id}>
                            {({ object: targetObject }) => {
                                return (
                                    <ActionButtonsSelector
                                        object={targetObject}
                                        selectedButtons={params.actionButtons || []}
                                        setConfig={({ pageButtons }) =>
                                            setAttr('actionButtons', pageButtons)
                                        }
                                        additionalActions={[]}
                                        editor="fields"
                                    />
                                )
                            }}
                        </WithObject>
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
})

const RelatedRecord = withObject(
    ({
        record = { _sid: null, _primary: null },
        object,
        content,
        label,
        columns = 3,
        actionButtons = [],
        context,
        field,
        layout,
        fieldConditionalVisibilityFilters = {},
        includeFields,
    }) => {
        const processFilter = useProcessFilter()
        const isEditing = get(context, 'editor.isEditing')
        const isCreate = get(context, 'view.creating')
        const isInlineCreate = get(context, 'view.isInlineCreate')
        const displayButtons = !isCreate && !isInlineCreate && actionButtons.length > 0
        const actionButtonsElement = displayButtons && record._sid && (
            <Box mt={4} ml={-2}>
                <ActionButtonsList
                    record={record}
                    object={object}
                    buttons={actionButtons}
                    editing={context.editor.isEditing || context.view.editing}
                    // style={attrs.actionButtons.buttonsStyle}
                    position="bottom"
                    includeFields={includeFields}
                />
            </Box>
        )

        return (
            <Container p={['container.padding', null, null, 'container.paddingLg']}>
                <Flex mb={2} justifyContent="space-between">
                    <Text
                        px={2}
                        py={1}
                        display="inline-block"
                        rounded="md"
                        background={V4DesignSystem.colors.gray[100]}
                        color={V4DesignSystem.colors.gray[700]}
                        size="sm"
                    >
                        {label || field.label}
                    </Text>
                    {record._sid && (
                        <RecordLinkDisplay
                            record={record}
                            recordId={record._sid}
                            noLink={false}
                            linkText="View record"
                            layout={layout}
                        />
                    )}
                </Flex>

                {record._primary ? (
                    <Text size="lg" fontWeight="bold">
                        {record._primary}
                    </Text>
                ) : (
                    <Text color={V4DesignSystem.colors.gray[600]} mt={4}>
                        No related record
                    </Text>
                )}

                {!!content.length && (isEditing || record._sid) && (
                    // @ts-expect-error
                    <FixedGrid
                        columns={[1, 1, columns, columns]}
                        spacing="1.5rem"
                        maxWidth="100%"
                        mt={6}
                    >
                        {content.map((item, index) => {
                            const {
                                objectId,
                                fieldId,
                                required,
                                fullWidth,
                                label,
                                description,
                                ...otherOptions
                            } = item

                            const passesVisibilityFilters =
                                !fieldConditionalVisibilityFilters?.[fieldId] ||
                                processFilter(
                                    [context.record],
                                    fieldConditionalVisibilityFilters[fieldId]
                                )?.length > 0

                            if (record._sid && passesVisibilityFilters) {
                                return (
                                    <AttributeDisplay
                                        isCreate={false}
                                        isInlineCreate={false}
                                        key={fieldId}
                                        objectId={objectId}
                                        fieldId={fieldId}
                                        record={record}
                                        required={required}
                                        fullWidth={fullWidth}
                                        readOnly={true}
                                        editing={context.view.editing}
                                        showErrors={context.view.showErrors}
                                        setValue={context.view.actions.setValue}
                                        setValid={context.view.actions.setValid}
                                        valid={context.view.valid}
                                        isLoading={context.view?.isLoading}
                                        labelOverride={label}
                                        editDescription={description}
                                        isVisible={true}
                                        {...otherOptions}
                                    />
                                )
                            }

                            if (isEditing) {
                                const field = object.fields.find((f) => f._sid === fieldId)
                                return (
                                    <Box
                                        key={index}
                                        style={fullWidth ? { gridColumn: '1 / -1' } : {}}
                                    >
                                        <Text variant="fieldLabel">
                                            {!otherOptions.hideLabel && field?.label}
                                        </Text>
                                        <Text variant="fieldText">-</Text>
                                    </Box>
                                )
                            }
                        })}
                    </FixedGrid>
                )}
                {actionButtonsElement}
            </Container>
        )
    }
)

const RelatedRecordExists = (props) => {
    const requiredFields = props.content.map((f) => f.fieldName)
    const { data: record } = useGetRecord({
        recordId: props.recordId,
        includeFields: requiredFields,
    })
    const { object } = useObject(record?._object_id)
    if (!record || !object) {
        return null
    }
    return (
        <RelatedRecord {...props} includeFields={requiredFields} object={object} record={record} />
    )
}

const RelatedRecordBlock = ({ attrs, context }) => {
    const isEditing = get(context, 'editor.isEditing')
    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters

    if (hidden) {
        return <></>
    }

    const placeholderIfEditing = isEditing ? (
        <ConfigureWidgetPlaceholder
            message="Related record"
            subtitle="Display a single related record"
            Icon={SVGIcons.RelatedRecord}
        />
    ) : (
        <></>
    )

    const { fieldId } = attrs
    if (!fieldId) {
        return placeholderIfEditing
    }

    const field = context.object?.fields?.find((f) => f._sid === fieldId)
    if (!field || get(field, 'connection_options.is_disabled', false)) {
        return placeholderIfEditing
    }

    const relatedRecordId = context?.record?.[field.api_name]
    let RelatedRecordComponent = relatedRecordId ? RelatedRecordExists : RelatedRecord

    return (
        <RelatedRecordComponent
            recordId={relatedRecordId}
            objectId={field?.link_target_object_id}
            content={attrs.content}
            label={attrs.label}
            columns={attrs.columns}
            context={context}
            field={field}
            actionButtons={attrs.actionButtons}
            fieldConditionalVisibilityFilters={attrs.fieldConditionalVisibilityFilters}
        />
    )
}

RelatedRecordBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        field: 'content',
        fullHeight: true,
        Element: RelatedRecordEditor,
        simple: true,
    }),
]

export default RelatedRecordBlock
