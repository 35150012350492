import React, { useMemo, useRef } from 'react'

import { Spinner } from '@chakra-ui/react'
import { mergeAttributes, Node } from '@tiptap/core'
import {
    InputRule,
    InputRuleMatch,
    NodeViewRendererProps,
    NodeViewWrapper,
    ReactNodeViewRenderer,
} from '@tiptap/react'
import { usePreviewRecordContext } from 'v2/views/List/PreviewRecord/PreviewRecordContext'

import { useAppContext } from 'app/AppContext'
import { getAbsoluteWorkspaceRootUrl } from 'app/UrlService'
import { useGetRecord } from 'data/hooks/records'
import { useStacks } from 'data/hooks/stacks'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { LinkButton } from 'ui/components/LinkButton'
// test
export type RecordLinkExtensionOptions = {
    fetchRecordsFn?: () => RecordDto[]
    fetchStacksFn?: () => StackDto[]
    fetchWorkspaceAccountFn?: () => Account | null
}

export function createRecordLinkExtension(options: RecordLinkExtensionOptions) {
    return Node.create<RecordLinkExtensionOptions>({
        name: 'recordLink',
        addOptions() {
            return {
                fetchRecordsFn: options.fetchRecordsFn,
                fetchStacksFn: options.fetchStacksFn,
                fetchWorkspaceAccountFn: options.fetchWorkspaceAccountFn,
            }
        },
        inline: true,
        group: 'inline',
        draggable: true,
        selectable: false,
        atom: true,
        addAttributes() {
            return {
                id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-id'),
                    renderHTML: (attributes) => ({
                        'data-id': attributes.id,
                    }),
                },
                stack_id: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-stack-id'),
                    renderHTML: (attributes) => ({
                        'data-stack-id': attributes.stack_id,
                    }),
                },
                url: {
                    default: null,
                    parseHTML: (element) => element.getAttribute('data-url'),
                    renderHTML: (attributes) => ({
                        'data-url': attributes.url,
                    }),
                },
            }
        },
        parseHTML() {
            return [
                {
                    tag: `record-link`,
                },
            ]
        },
        renderHTML({ HTMLAttributes }) {
            return ['record-link', mergeAttributes(HTMLAttributes)]
        },
        renderText({ node }) {
            // Make sure we have an absolute Record URL.
            const url = node.attrs.url
            if (url.startsWith('http')) return url

            const workspaceAccount = this.options.fetchWorkspaceAccountFn?.()
            const stacks = this.options.fetchStacksFn?.()
            const stack = stacks?.find((stack) => stack._sid === node.attrs.stack_id)

            if (!stack || !workspaceAccount) {
                return `${window.location.origin}${url}`
            }

            const workspaceUrl = getAbsoluteWorkspaceRootUrl(workspaceAccount)
            let relativeUrl = url
            if (!workspaceAccount.sso_required) {
                relativeUrl = url.split(workspaceAccount.slug)[1]
            }

            return `${workspaceUrl}${relativeUrl}`
        },
        addInputRules() {
            return [
                new InputRule({
                    find: (content) =>
                        parseDetailViewInputURL(
                            content,
                            this.options.fetchWorkspaceAccountFn,
                            this.options.fetchStacksFn
                        ),
                    handler: ({ state, range, match }) => {
                        if (!match[0]) return

                        const attributes = match.data
                        const { tr } = state

                        let start = range.from
                        let end = range.to

                        const matchedInput = match.input
                        const numOfQuotes = matchedInput ? matchedInput.split('`').length - 1 : 0
                        const isWithinQuotes = numOfQuotes % 2 === 1

                        // Prevent links from being added inside a code mark or an unfinished code mark.
                        const codeMark = state.schema.marks.code
                        if (
                            isWithinQuotes ||
                            !codeMark ||
                            state.doc.rangeHasMark(start, end, codeMark)
                        ) {
                            return
                        }

                        // Insert leading whitespace back in.
                        const firstChar = match[0][0]
                        if (firstChar && whitespaceRegexp.test(firstChar)) {
                            start = start + 1
                        }

                        tr.replaceWith(start, end, [
                            this.type.create(attributes),
                            // Add trailing whitespace.
                            state.schema.text(' '),
                        ])
                    },
                }),
            ]
        },
        addNodeView() {
            // @ts-expect-error
            return ReactNodeViewRenderer(RecordLinkComponent)
        },
        addStorage() {
            return {
                records: () => [],
            }
        },
        onBeforeCreate() {
            this.storage.records = () => {
                const records: Record<
                    string,
                    {
                        id: string
                        stack_id: string
                    }
                > = {}

                this.editor.state.doc.descendants((node) => {
                    if (node.type.name === this.type.name) {
                        if (records.hasOwnProperty(node.attrs.id)) return

                        records[node.attrs.id] = {
                            id: node.attrs.id,
                            stack_id: node.attrs.stack_id,
                        }
                    }
                })

                return Object.values(records)
            }
        },
    })
}

export function useRecordLinkExtension(records?: RecordDto[]) {
    const { workspaceAccount } = useAppContext()
    const workspaceAccountRef = useRef(workspaceAccount)

    const { data: stacks } = useStacks()
    const stacksRef = useRef(stacks)

    const recordsRef = useRef(records)
    recordsRef.current = records
    return useMemo(() => {
        const options = {
            fetchStacksFn: () => stacksRef.current ?? [],
            fetchWorkspaceAccountFn: () => workspaceAccountRef.current,
            fetchRecordsFn: () => recordsRef.current ?? [],
        }

        return createRecordLinkExtension(options)
    }, [])
}

const whitespaceRegexp = /\s/

export function parseDetailViewInputURL(
    content: string,
    fetchWorkspaceAccountFn?: () => Account | null,
    fetchStacksFn?: () => StackDto[],
    includeTrailingSpace = true
): InputRuleMatch | null {
    const workspaceAccount = fetchWorkspaceAccountFn?.()
    const stacks = fetchStacksFn?.()

    if (!workspaceAccount || !stacks) return null

    const regexpPatternParts: string[] = [
        // Optional space at the beginning.
        '(?:^|\\s)(',
        // https://www.
        '(https?:\\/\\/)(?:www\\.)?',
    ]

    // <workspace-domain + <slug> (if not custom domain).
    const workspaceDomain = getAbsoluteWorkspaceRootUrl(workspaceAccount)
    const encodedWorkspaceDomain = encodeUrl(workspaceDomain)
    regexpPatternParts.push(encodedWorkspaceDomain)

    // /<stack-name>/<feature-url>/view/<record-id>
    regexpPatternParts.push(
        '\\/([^\\/\\s]+)\\/([^\\/\\s]+)(?:\\/view\\/)([^\\s\\/#?]+)([#?&\\/][^\\s]+)?'
    )
    if (includeTrailingSpace) {
        // Optional space at the end.
        regexpPatternParts.push('(?:\\s)')
    }
    regexpPatternParts.push(')$')

    const regexp = new RegExp(regexpPatternParts.join(''))
    const detailViewUrlMatch = regexp.exec(content)
    if (!detailViewUrlMatch) return null

    const [text, , , stackSlug, , recordId] = detailViewUrlMatch

    const stack = stacks.find((stack) => stack.url_slug === stackSlug)
    if (!stack) return null

    let relativeUrl = text.trim().split(workspaceDomain)[1]
    // Include the workspace slug in the URL if we're on the studio domain.
    if (!workspaceAccount.sso_required) {
        relativeUrl = `/${workspaceAccount.slug}${relativeUrl}`
    }

    return {
        index: detailViewUrlMatch.index + 1,
        text: text,
        data: {
            id: recordId,
            url: relativeUrl,
            stack_id: stack._sid,
        },
    }
}

function encodeUrl(url: string) {
    return url.replace(/(https?:\/\/)(?:www\.)?/, '').replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
}

type RecordLinkComponentProps = NodeViewRendererProps & {}

const RecordLinkComponent: React.FC<RecordLinkComponentProps> = ({ node, extension }) => {
    const { id, stack_id, url } = node.attrs

    let recordId = id
    // In case this is an encoded record id, we need to extract the record id.
    if (recordId.includes('|')) {
        const parts = recordId.split('|')
        recordId = parts[2]?.replace('record_', '')
    }

    const { selectedStack } = useAppContext()

    const { previewRecord } = usePreviewRecordContext()
    const records = (extension.options.fetchRecordsFn?.() as RecordDto[]) ?? []
    let record = records.find((r) => r._sid === recordId)

    const { data, isLoading } = useGetRecord({
        recordId: recordId,
        includeFields: [],
        options: { stackId: stack_id },
        useQueryOptions: { enabled: !record },
    })
    record = record ?? data

    if (!recordId) return null

    const isExternal = selectedStack?._sid !== stack_id

    // the record is unavailable due to permissions
    const isInaccessible = !isExternal && !record && !isLoading

    const title = isInaccessible ? 'Inaccessible Record' : (record?._primary ?? recordId)

    const treatAsExternalLink = isExternal || isInaccessible

    return (
        <NodeViewWrapper as="span" className="record-link">
            <LinkButton
                to={url}
                target={treatAsExternalLink ? '_blank' : undefined}
                variant="link"
                size="2xs"
                title={title}
                aria-disabled={isLoading}
                flex
                center
                fontWeight="bodyBold"
                maxWidth="full"
                flexWrap="nowrap"
                style={{
                    userSelect: 'text',
                }}
                data-drag-handle
                draggable="true"
                onClick={(e) => {
                    if (treatAsExternalLink || !record) {
                        return
                    }

                    previewRecord({ recordId, objectId: record?._object_id })
                    e.preventDefault()
                }}
            >
                <Box>{isLoading ? <Spinner size="xs" /> : <Icon name="FileText" />}</Box>
                <Box as="span" width="full" trim>
                    {title}
                </Box>
                {treatAsExternalLink && <Icon name="ExternalLink" size="s" />}
            </LinkButton>
        </NodeViewWrapper>
    )
}
