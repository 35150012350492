import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import { useStacks } from 'data/hooks/stacks'
import { NavTreeItem } from 'features/admin/settings/navigation/types'
import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'
import { Icon } from 'ui/components/Icon'

import { matchesSearchTerms } from './helpers'
import { Highlighter } from './Highlighter'
import { useGlobalNavigation } from './useGlobalNavigation'

type SearchableNavTreeItem = NavTreeItem & {
    stack: StackDto
    parents: NavTreeItem[]
    searchText: string
    isStack?: boolean
}
export const useNavigationSearchProvider = ({
    enabled = true,
}: {
    enabled: boolean
}): ItemProvider<SearchableNavTreeItem> => {
    const { globalNavTree } = useGlobalNavigation({ enabled })
    const { data: stacks } = useStacks()
    const { workspaceAccount } = useAppContext()

    const treeItemsWithParents = useMemo(() => {
        if (!globalNavTree || !stacks) return []

        const stacksById = stacks.reduce<{ [key: string]: StackDto }>(
            (acc, stack) => ({ ...acc, [stack._sid]: stack }),
            {}
        )

        const searchableNavItems: SearchableNavTreeItem[] = []
        function populateParents(
            tree: NavTreeItem[],
            parents: NavTreeItem[],
            parentsNames: string
        ): SearchableNavTreeItem[] {
            const newTree: SearchableNavTreeItem[] = tree
                .map((item) => {
                    const stack = stacksById[item.stack_id]
                    if (!stack) return

                    return {
                        ...item,
                        stack,
                        parents,
                        url: getUrl(item.url, stack),
                        searchText: [
                            !parents.length ? stack.name : undefined,
                            parentsNames,
                            item.label,
                        ]
                            .filter(Boolean)
                            .join(' '),
                    }
                })
                .filter((item): item is SearchableNavTreeItem => !!item)
            newTree.forEach((item) => {
                if (item.children) {
                    item.children = populateParents(
                        item.children,
                        item.children.length > 1 ? [...parents, item] : parents,
                        item.searchText
                    )
                } else {
                    searchableNavItems.push(item)
                }
            })
            return newTree
        }

        populateParents(globalNavTree, [], '')

        // Add the stacks in so they are searchable too
        const stackItems = stacks
            .filter((stack) => stack.account_id === workspaceAccount?._sid)
            .map((stack) => ({
                stack,
                parents: [],
                searchText: stack.name,
                isStack: true,
                url: getUrl('/', stack),
            }))
        return [...stackItems, ...searchableNavItems]
    }, [globalNavTree, stacks, workspaceAccount?._sid])
    return useMemo(
        () =>
            ({
                id: 'pages',
                title: 'Apps and Pages',
                maxVisibleItemsDefault: 10,
                getItems({ queryTerms }) {
                    return Promise.resolve({
                        items: treeItemsWithParents.filter(
                            (u) =>
                                queryTerms.length > 0 &&
                                matchesSearchTerms(u.searchText, queryTerms)
                        ),
                    })
                },
                renderItem({ item, queryTerms }) {
                    return <RenderNavigationLink item={item} queryTerms={queryTerms} />
                },
            }) as ItemProvider<SearchableNavTreeItem>,
        [treeItemsWithParents]
    )
}

function RenderNavigationLink({
    item,
    queryTerms,
}: {
    item: SearchableNavTreeItem
    queryTerms?: string[]
}) {
    const handleClick = useCallback((e: React.MouseEvent) => {
        // @ts-ignore
        e.nativeEvent.preventDownshiftDefault = true
    }, [])
    return (
        <Box as={Link} to={item.url} flex center color="text" onClick={handleClick} tabIndex={-1}>
            <StackIconBadge stack={item.stack} size={20} mr="xs" />
            <Box>
                <Highlighter terms={queryTerms || []}>{item.stack.name}</Highlighter>
            </Box>
            {!item.isStack && (
                <Icon color="textWeakest" size="xs" noShrink name="ChevronRight" mx="3xs" />
            )}
            {item.parents.map((parent) => (
                <>
                    <Box key={parent._sid}>
                        <Highlighter terms={queryTerms || []}>{parent.label}</Highlighter>
                    </Box>

                    <Icon color="textWeakest" size="xs" noShrink name="ChevronRight" mx="3xs" />
                </>
            ))}
            <Box>
                <Highlighter terms={queryTerms || []}>{item.label}</Highlighter>
            </Box>
        </Box>
    )
}
