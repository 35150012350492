import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'

type UseBoardViewTitleStateOptions = {
    record?: RecordDto
}

export function useBoardViewTitleState(options: UseBoardViewTitleStateOptions) {
    const { record } = options

    const { titleField, attributes, view } = useBoardViewContext()

    const effectiveTitleField = useMemo(
        () => titleField || attributes[0]?.field,
        [titleField, attributes]
    )
    const fieldDisplayOptions = useMemo(
        () =>
            titleField
                ? view.options.boardCardTitle?.fieldDisplayOptions
                : attributes[0]?.rawOptions,
        [titleField, view.options.boardCardTitle?.fieldDisplayOptions, attributes]
    )

    const titleAttribute: BoardViewAttribute | undefined = useMemo(() => {
        if (!effectiveTitleField) return undefined

        return {
            id: effectiveTitleField._sid,
            title: effectiveTitleField.label,
            field: effectiveTitleField,
            rawOptions: fieldDisplayOptions ?? {},
            type: 'title',
        }
    }, [effectiveTitleField, fieldDisplayOptions])

    const contentAttributes = useMemo(
        () => (titleField ? attributes : attributes.slice(1)),
        [titleField, attributes]
    )
    const recordTitle =
        effectiveTitleField && record
            ? (record[effectiveTitleField?.api_name] ?? undefined)
            : undefined

    return useMemo(
        () => ({ recordTitle, titleAttribute, contentAttributes }),
        [titleAttribute, recordTitle, contentAttributes]
    )
}
