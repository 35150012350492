import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useDeleteRecentRecord, useRecentRecords } from 'data/hooks/recentRecords/recentRecords'
import { RecentRecord } from 'data/hooks/recentRecords/types'
import { assertIsDefined } from 'data/utils/ts_utils'
import { StackIconBadge } from 'features/core/StackIconBadge'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'
import { Icon } from 'ui/components/Icon'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'

import { matchesSearchTerms } from './helpers'
import { Highlighter } from './Highlighter'

type RecentRecordSearchResult = RecentRecord & {
    _sid: string
    _stack_id: string
    _primary: unknown
    _object_id: string
    url: string
}

function getSearchItem(
    item: RecentRecord,
    objectUrls: Record<string, string>
): RecentRecordSearchResult {
    return {
        ...item,
        _sid: item.record._sid!,
        url: getUrl(
            `${objectUrls[item.object._sid!]}/view/${item.record._sid}`,
            item.stack as StackDto
        ),
        _stack_id: item.stack._sid!,
        _primary: item.record._primary!,
        _object_id: item.object._sid!,
    }
}

type useRecentItemsSearchProviderProps = {
    blockLinkClick?: boolean
}

export const useRecentItemsSearchProvider = ({
    blockLinkClick,
}: useRecentItemsSearchProviderProps = {}): ItemProvider<RecentRecordSearchResult> => {
    const { data } = useRecentRecords()

    return useMemo(
        () =>
            ({
                id: 'recent_records',
                title: 'Recent records',
                maxVisibleItemsDefault: 10,
                // show all recent items immediately on open without
                // waiting for getItems to be called
                initialItems: data.recentRecords.map((item) =>
                    getSearchItem(item, data.objectUrls)
                ),
                getItems({ queryTerms }) {
                    return Promise.resolve({
                        items: data.recentRecords
                            .filter((u) =>
                                matchesSearchTerms(u.record._primary!.toString(), queryTerms)
                            )
                            .map((item) => getSearchItem(item, data.objectUrls)),
                    })
                },
                renderItem({ item, queryTerms }) {
                    return (
                        <RenderRecentRecord
                            item={item}
                            queryTerms={queryTerms}
                            blockLinkClick={blockLinkClick}
                        />
                    )
                },
            }) as ItemProvider<RecentRecordSearchResult>,
        [blockLinkClick, data.objectUrls, data.recentRecords]
    )
}

function RenderRecentRecord({
    item,
    queryTerms,
    blockLinkClick,
}: {
    item: RecentRecordSearchResult
    queryTerms?: string[]
    blockLinkClick?: boolean
}) {
    const { mutate: deleteRecentRecord } = useDeleteRecentRecord()

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            // If the caller doesn't want a click to activate the link,
            // we prevent default here--unless ctrl or meta is pressed, then
            // we want to let the user click on the link for opening in a new tab
            if (blockLinkClick && !(e.ctrlKey || e.metaKey)) {
                e.preventDefault()
            } else {
                // @ts-ignore
                e.nativeEvent.preventDownshiftDefault = true
            }
        },
        [blockLinkClick]
    )

    const handleDelete = useCallback(
        (e: React.MouseEvent) => {
            // @ts-ignore
            e.nativeEvent.preventDownshiftDefault = true
            e.preventDefault()
            assertIsDefined(item.record._sid)
            deleteRecentRecord(item.record._sid)
        },
        [deleteRecentRecord, item.record._sid]
    )
    return (
        <Box
            as={Link}
            className={HoverContainerStyle}
            to={item.url}
            flex
            center
            maxWidth="full"
            width="full"
            color="text"
            onClick={handleClick}
            tabIndex={-1}
        >
            <StackIconBadge stack={item.stack as StackDto} size={20} mr="m" />
            <Box flex column grow shrink>
                <Box flex center>
                    <Box fontWeight="bodyBold" mr="l" trim grow>
                        <Highlighter terms={queryTerms || []}>{item.record._primary}</Highlighter>
                    </Box>
                    <Box flex center fontSize="bodyS">
                        <Box color="textWeak">{item.stack.name}</Box>
                        <Icon color="textWeakest" noShrink name="ChevronRight" size="xs" mx="3xs" />
                        <Box color="textWeak">{item.object.name}</Box>
                    </Box>
                    <Button
                        variant="ghost"
                        startIcon={{ name: 'X' }}
                        onClick={handleDelete}
                        className={OpacityOnContainerHover}
                        size="2xs"
                        ml="m"
                        style={{ marginRight: '-8px' }}
                    />
                </Box>
            </Box>
        </Box>
    )
}
