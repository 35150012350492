import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useDeleteGrant } from 'data/hooks/accessGrants'
import { SharingSettingsPatch, useUpdateStackSharingSettings } from 'data/hooks/stacks'
import { refetchAppUsersForAdmin } from 'data/hooks/users/main'
import { AppGroup, AppRole, AppUser } from 'features/users/AppUsers/types'

import { DropdownItem } from 'ui/components/Dropdown'
import { IconName } from 'ui/components/Icon/Icon'
import { useToast } from 'ui/components/Toast'
import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 30

type UseAppUsersRoleDropdownStateOptions = {
    roles: AppRole[]
    user?: AppUser
    group?: AppGroup
}

type RoleOption = {
    label: string
    value: string
    isAdmin: boolean
    icon: IconName
    variant: React.ComponentPropsWithoutRef<typeof DropdownItem>['variant']
}

export function useAppUsersRoleDropdownState(options: UseAppUsersRoleDropdownStateOptions) {
    const { roles, user, group } = options

    const roleValue = group?.role ?? user?.role ?? ''

    const isCurrentRoleAdmin = roleValue === 'internal_admin'

    const isGroup = !!group

    const roleOptions: RoleOption[] = useMemo(() => {
        const options = roles.map((role) => {
            const value = role.id
            const isAdmin = value === 'internal_admin'

            let label = truncateText(role.name, MAX_LABEL_LENGTH)
            if (isAdmin) {
                if (isCurrentRoleAdmin) {
                    label = 'Administrator'
                } else {
                    label = 'Upgrade to administrator'
                }
            }

            const icon: RoleOption['icon'] = isAdmin ? 'Crown' : 'Lock'

            const variant: RoleOption['variant'] = isAdmin ? 'action' : 'default'

            return {
                label,
                value,
                isAdmin,
                icon,
                variant,
            }
        })

        return options.sort((a, b) => {
            // Always show admins first.
            if (a.isAdmin && !b.isAdmin) return -1
            if (!a.isAdmin && b.isAdmin) return 1

            // Otherwise sort by label alphabetically.
            return a.label.localeCompare(b.label)
        })
    }, [isCurrentRoleAdmin, roles])

    const currentOption = roleOptions.find((option) => option.value === roleValue)

    const label = useMemo(() => {
        if (!currentOption) return 'Pick a role'

        if (currentOption.isAdmin) {
            return 'Administrator'
        }

        return currentOption.label
    }, [currentOption])

    const { selectedStack } = useAppContext()
    const stackRef = useRef(selectedStack)
    stackRef.current = selectedStack

    const toast = useToast()

    const validateAdminUsersAfterPatch = useCallback(
        (patch: SharingSettingsPatch, isGroup: boolean = false) => {
            const currentSharing = stackRef.current?.sharing

            const patchData = Object.entries(patch).reduce(
                (result, [key, value]) => {
                    if (!value.deleted) {
                        result![key] = value.role
                    }

                    return result
                },
                {} as StackDto['sharing']
            )

            const sharingWithPatch = {
                ...currentSharing,
                groups: isGroup
                    ? { ...currentSharing?.groups, ...patchData }
                    : currentSharing?.groups,
                users: !isGroup
                    ? { ...currentSharing?.users, ...patchData }
                    : currentSharing?.users,
            }

            // Check if there will be any admins left after this change.

            if (
                sharingWithPatch.groups &&
                Object.values(sharingWithPatch.groups).some((role) => role === 'internal_admin')
            ) {
                return true
            }

            if (
                sharingWithPatch.users &&
                Object.values(sharingWithPatch.users).some((role) => role === 'internal_admin')
            ) {
                return true
            }

            toast({
                type: 'error',
                startIcon: {
                    name: 'AlertCircle',
                },
                title: 'There must be at least one admin user',
            })

            return false
        },
        [toast]
    )

    const updateStackSharingSettings = useUpdateStackSharingSettings()

    const patchStackSharingSettings = useCallback(
        async (patch: SharingSettingsPatch) => {
            if (!validateAdminUsersAfterPatch(patch, isGroup)) return Promise.resolve()

            const stack = stackRef.current
            if (!stack) return Promise.resolve()

            await updateStackSharingSettings(stack, patch)
            await refetchAppUsersForAdmin()
        },
        [isGroup, updateStackSharingSettings, validateAdminUsersAfterPatch]
    )

    const onChange = useCallback(
        async (value: string) => {
            try {
                const subjectId = group?.id ?? user?.id ?? ''
                if (!subjectId) return

                const patch: SharingSettingsPatch = {
                    [subjectId]: { role: value },
                }

                await patchStackSharingSettings(patch)
            } catch {
                toast({
                    type: 'error',
                    startIcon: {
                        name: 'AlertCircle',
                    },
                    title: 'There was a problem updating the role',
                    helperText: 'Please try again later. If the issue persists, contact support.',
                })
            }
        },
        [group?.id, patchStackSharingSettings, toast, user?.id]
    )

    const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] = useState(false)

    const { mutateAsync: deleteGrant } = useDeleteGrant()

    const handleRevokeAccess = useCallback(async () => {
        if (isGroup) {
            try {
                await deleteGrant(group!.id)
            } catch {
                toast({
                    type: 'error',
                    startIcon: {
                        name: 'AlertCircle',
                    },
                    title: 'There was a problem revoking group access',
                    helperText: 'Please try again later. If the issue persists, contact support.',
                })
            }
        } else {
            try {
                const patch: SharingSettingsPatch = {
                    [user!.id]: { role: user!.role, deleted: true },
                }

                await patchStackSharingSettings(patch)
            } catch {
                toast({
                    type: 'error',
                    startIcon: {
                        name: 'AlertCircle',
                    },
                    title: 'There was a problem revoking user access',
                    helperText: 'Please try again later. If the issue persists, contact support.',
                })
            }
        }

        setIsRevokeAccessModalOpen(false)
    }, [deleteGrant, group, isGroup, patchStackSharingSettings, toast, user])

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    useLayoutEffect(() => {
        // Close the dropdowns and modals when the user changes.
        setIsDropdownOpen(false)
        setIsRevokeAccessModalOpen(false)
    }, [user?.id])

    return {
        roleOptions,
        label,
        isCurrentRoleAdmin,
        onChange,
        value: roleValue,
        isRevokeAccessModalOpen,
        onRevokeAccessModalOpenChange: setIsRevokeAccessModalOpen,
        handleRevokeAccess,
        isDropdownOpen,
        onDropdownOpenChange: setIsDropdownOpen,
    }
}
