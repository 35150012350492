import React, { useCallback, useImperativeHandle, useMemo, useRef } from 'react'

import { Editor, NodeViewRendererProps } from '@tiptap/core'
import { Mention } from '@tiptap/extension-mention'
import { NodeViewWrapper, Range, ReactNodeViewRenderer } from '@tiptap/react'
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion'

import { useUserSearchProvider } from 'features/Search/UserSearchProvider'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'

import { renderSuggestionComponent, SuggestionComponentHandle } from './extensionHelpers'

import { MentionsItemStyles, MentionsListStyles } from './MentionsExtension.css'

export type MentionsOptions = {
    fetchUsersFn: () => UserDto[]
}

export type MentionUser = {
    id: string
    label: string
}

export function createMentionsExtension(options: MentionsOptions) {
    return Mention.extend({
        addStorage() {
            return {
                mentions: () => [],
            }
        },
        onBeforeCreate() {
            this.storage.mentions = () => {
                const users = new Set<string>()

                this.editor.state.doc.descendants((node) => {
                    if (node.type.name === this.type.name) {
                        users.add(node.attrs.id)
                    }
                })

                return Array.from(users)
            }
        },
        addNodeView() {
            // @ts-expect-error
            return ReactNodeViewRenderer(MentionComponent, {
                as: 'span',
            })
        },
        addOptions() {
            return {
                ...this.parent?.(),
                fetchUsersFn: options.fetchUsersFn,
            }
        },
    }).configure({
        HTMLAttributes: {
            class: MentionsItemStyles,
        },
        suggestion: {
            items: options.fetchUsersFn,
            render: () => {
                return renderSuggestionComponent(MentionsList)
            },
        } as Omit<SuggestionOptions<UserDto>, 'editor'>,
    })
}

type MentionsListProps = SuggestionProps<UserDto> & {}

export function userToMentionAttrs(user: UserDto): MentionUser {
    return {
        id: user._sid,
        label: user.name || user.email,
    }
}
export const MentionsList = React.forwardRef<SuggestionComponentHandle, MentionsListProps>(
    ({ query, editor, range, items: users }, ref) => {
        const usersProvider = useUserSearchProvider({ showOnEmptySearch: true, users })

        const onItemSelected = useCallback(
            (item) => insertMention(editor, range, item),
            [editor, range]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected,
            providers: [usersProvider],
            itemToString: () => '',
            debounceDelay: 10,
        })

        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps(undefined, { suppressRefError: true }).onKeyDown(event)
                return event.defaultPrevented
            },
        }))
        const { isLoading, collections, showMore, items } = itemsState

        if (!items.length) return null
        return (
            <ComboboxContext.Provider value={comboboxState}>
                <Box
                    as="ul"
                    role="listbox"
                    aria-label="Mention a user"
                    className={MentionsListStyles}
                >
                    <ComboboxList
                        collections={collections}
                        queryTerms={queryTerms}
                        showMore={showMore}
                        isLoading={isLoading}
                    />
                </Box>
            </ComboboxContext.Provider>
        )
    }
)

export function useMentionsExtension(users: UserDto[]) {
    // The extension needs to only be created once, but the users can change.
    const usersRef = useRef(users)
    usersRef.current = users

    return useMemo(() => {
        return createMentionsExtension({
            fetchUsersFn: () => usersRef.current,
        })
    }, [])
}

export function insertMention(editor: Editor, range: Range, user: UserDto) {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter
    const overrideSpace = nodeAfter?.text?.startsWith(' ')

    if (overrideSpace) {
        range.to += 1
    }

    queueMicrotask(() => {
        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: 'mention',
                    attrs: userToMentionAttrs(user),
                },
                {
                    type: 'text',
                    text: ' ',
                },
            ])
            .run()

        window.getSelection()?.collapseToEnd()
    })
}

type MentionComponentProps = NodeViewRendererProps & {}

const MentionComponent: React.FC<MentionComponentProps> = ({ node, extension }) => {
    const userId = node.attrs.id
    const users = extension.options.fetchUsersFn() as UserDto[]

    const user = useMemo(() => users.find((u) => u._sid === userId), [userId, users])
    const label = user?.name || user?.email || node.attrs.label

    return (
        <NodeViewWrapper as="span" className={MentionsItemStyles} contentEditable={false}>
            @{label}
        </NodeViewWrapper>
    )
}
