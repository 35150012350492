/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */
/* eslint-disable simple-import-sort/imports -- Disabled to avoid circular dependencies, remove when file is removed from .freezircularDeps */

import { jsx } from '@emotion/react'
import * as Sentry from '@sentry/react'
import WithObject from 'data/wrappers/WithObject'
import ActionButtonList from 'features/actions/ActionButtonList'
import { useActionButtons } from 'features/actions/ActionsContextProvider'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import get from 'lodash/get'
import { useProcessFilter } from 'features/records/components/RecordFilters'
import React, { useState, useMemo } from 'react'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import {
    Box,
    Button,
    Collapse,
    ConfigureWidgetPlaceholder,
    Container,
    FixedGrid,
    Flex,
    Heading,
    Icon,
    MarkdownText,
    Text,
} from 'v2/ui'
import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'
import * as SVGIcons from 'v2/ui/svgs'
import useDimension from 'v2/ui/utils/useDimension'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'
import useRefCallback from 'v2/ui/utils/useRefCallback'
import FieldContainerEditor from './FieldContainerEditor/FieldContainerEditor'
import { getDetailRequiredFieldApiNames } from 'v2/views/Detail/detailRequiredFields'
import { useFields } from 'data/hooks/fields'
import { useActions } from 'data/hooks/actions'

const sectionise = (contents) => {
    // Converts a stream of field-container items into a list of sections containing field-type items.
    // A section looks like this: { label: "...", description: "...", contents: [...] }
    // Where label and description are optional, but contents always exists.
    // Empty sections are removed, even if that removes all sections.
    // N.B. fields may have been removed already by FieldLayoutEditor filterFields if they are disabled or non-permissioned.

    // Group everything by section
    const sections = []
    let currentSection = { contents: [], label: undefined, description: undefined }
    contents.forEach((item) => {
        if (item.type === 'field') {
            currentSection.contents.push(item)
        } else if (item.type === 'section') {
            // Save the old section and restart with a fresh one
            sections.push(currentSection)
            currentSection = { ...item, contents: [] }
        } else {
            // Ignore this unknown item type
            Sentry.captureMessage(`Unexpected item type found in a field container: ${item.type}`)
        }
    })
    // Save the last section
    sections.push(currentSection)

    // Remove any empty sections
    const validSections = sections.filter((section) => section.contents.length > 0)

    return validSections
}

const InnerFieldContainerBlock = (props) => {
    const [state, setState] = useState({ collapsedSections: {} })
    const { attrs, context, block } = props
    const isCreate = get(context, 'view.creating')
    const isInlineCreate = get(context, 'view.isInlineCreate')
    const [gridRef, setGridRef] = useRefCallback()
    const { width: gridWidth } = useDimension(gridRef)

    const processFilter = useProcessFilter()
    const isEditing = get(props, 'context.editor.isEditing')
    const fieldConditionalVisibilityFilters = attrs?.fieldConditionalVisibilityFilters
    const configuredFields = get(props, 'attrs.contents', [])
    const contents = useMemo(
        () =>
            configuredFields?.filter(
                (item) =>
                    isEditing ||
                    !fieldConditionalVisibilityFilters?.[item.fieldId] ||
                    processFilter([context.record], fieldConditionalVisibilityFilters[item.fieldId])
                        ?.length > 0
            ),
        [
            configuredFields,
            context.record,
            processFilter,
            fieldConditionalVisibilityFilters,
            isEditing,
        ]
    )

    const sections = sectionise(contents)

    // If there are no visible sections at all, then show an edit control instead in edit mode.
    const isLoading = props.context.view?.isLoading ?? false

    const passesFilters = useAttributeRecordFilter(attrs, context.record)
    const hidden = !isEditing && passesFilters

    const isMobile = useIsMobile()

    const { isOpen: isEditMode } = useEditMode()

    const fields = useFields({ objectId: context.object._sid }).data
    const actions = useActions().data
    const includeFields = getDetailRequiredFieldApiNames({
        actions,
        fields,
        obj: context.object,
        // we want to find the required fields for the underlying view and not the view the action buttons in
        view: { options: context.viewConfig },
    })

    if (isEditing && sections.length === 0) {
        return (
            <ConfigureWidgetPlaceholder
                message="Fields"
                subtitle="Display any combination of fields"
                // icon={<SVGIcons.Fields width={50} />}
                Icon={SVGIcons.Fields}
                onClick={props.showAttributePopover}
            />
        )
    }
    const sectionComponents = sections.map((section, index) => {
        const children = section.contents.map((item) => {
            const {
                objectId,
                fieldId,
                required,
                fullWidth,
                enableCopyPaste,
                readOnly,
                label,
                description,
                ...otherOptions
            } = item

            return (
                <AttributeDisplay
                    isCreate={isCreate}
                    isInlineCreate={isInlineCreate}
                    key={fieldId}
                    objectId={objectId}
                    fieldId={fieldId}
                    record={context.record}
                    required={required}
                    fullWidth={fullWidth}
                    enableCopyPaste={enableCopyPaste}
                    readOnly={readOnly}
                    editing={context.view.editing}
                    showErrors={context.view.showErrors}
                    setValue={context.view.actions.setValue}
                    setValid={context.view.actions.setValid}
                    setEditing={context.view.actions.setEditing}
                    setFocusField={context.view.actions.setFocusField}
                    focusField={context.view.focusField}
                    valid={context.view.valid}
                    labelOverride={label}
                    editDescription={description}
                    isVisible={!hidden}
                    isLoading={isLoading}
                    doubleClickToEdit={!isEditMode}
                    {...otherOptions}
                    blockId={block?.id}
                />
            )
        })

        let columns = isCreate ? 1 : section.columns || 3

        switch (true) {
            case gridWidth < 320:
                columns = Math.min(columns, 1)
                break
            case gridWidth < 520:
                columns = Math.min(columns, 2)
                break
            case gridWidth < 720:
                columns = Math.min(columns, 3)
                break
        }

        const Wrapper = context.view.isInlineCreate ? Box : Container

        const displayButtons =
            !isCreate && !isInlineCreate && attrs.pageButtons?.buttons?.length > 0
        const actionButtonListProps = {
            record: context.record,
            object: context.object,
            buttons: attrs.pageButtons?.buttons,
            editing: context.editor?.isEditing || context.view?.editing,
            style: attrs.pageButtons?.buttonsStyle,
            position: attrs.pageButtons?.position,
            includeFields,
        }

        const sectionCollapsed =
            typeof state.collapsedSections[section.id] === 'undefined'
                ? attrs.startCollapsed
                : state.collapsedSections[section.id]
        const canCollapse = attrs.canCollapse && !context.view.isInlineCreate

        const containerPadding = context.view.isInlineCreate
            ? 0
            : ['container.padding', null, null, 'container.paddingLg']

        const hasTitleSection = !!(
            section.label?.length ||
            section.description?.ops?.length ||
            section.description?.length
        )

        const actionButtonsInHeader =
            displayButtons && attrs.pageButtons.position === 'top' && index === 0

        return (
            <Collapse ref={setGridRef} isOpen={!hidden} duration={0.4} key={section.id}>
                <Wrapper mb={4}>
                    {hasTitleSection && (
                        <Box flexDirection={isMobile ? 'column' : 'row'} p={containerPadding}>
                            <FieldContainerTitle
                                title={section.label}
                                description={section.description}
                                collapsed={sectionCollapsed}
                                canCollapse={canCollapse}
                                isMobile={isMobile}
                                toggleCollapse={() => {
                                    setState((state) => {
                                        return {
                                            ...state,
                                            collapsedSections: {
                                                ...state.collapsedSections,
                                                [section.id]: !sectionCollapsed,
                                            },
                                        }
                                    })
                                }}
                            >
                                {actionButtonsInHeader && displayButtons && (
                                    <ActionButtonsList {...actionButtonListProps} />
                                )}
                            </FieldContainerTitle>
                        </Box>
                    )}

                    <Collapse
                        p={containerPadding}
                        isOpen={!!(!sectionCollapsed || !attrs.canCollapse)}
                    >
                        {/* Note: this reduces the spacing between the header and the content
                                with the constraints that a) you can't change the padding of either
                                of them because both could be in the content box on their own, and
                                b) if you rely on margin collapse then you get a jump when showing/
                                hiding the section. For inline creation forms, add a little spacing
                                instead */}
                        {hasTitleSection &&
                            (context.view.isInlineCreate ? (
                                <Box mt={4}></Box>
                            ) : (
                                <Box mt={[-3, null, null, -6]}></Box>
                            ))}

                        {actionButtonsInHeader && !hasTitleSection && displayButtons && (
                            <ActionButtonsList
                                {...actionButtonListProps}
                                renderWrapper={(actionButtons) => (
                                    <Flex justifyContent="flex-end" mb={6} id="button_wrapper">
                                        {actionButtons}
                                    </Flex>
                                )}
                            />
                        )}

                        <FixedGrid
                            columns={[1, 1, columns, columns]}
                            spacing="1.5rem"
                            maxWidth="100%"
                        >
                            {!hidden && children}
                        </FixedGrid>
                        {displayButtons &&
                            attrs.pageButtons.position !== 'top' &&
                            index === sections.length - 1 && (
                                <ActionButtonsList
                                    {...actionButtonListProps}
                                    renderWrapper={(actionButtons) => (
                                        <Box mt={4} ml={-2}>
                                            {actionButtons}
                                        </Box>
                                    )}
                                />
                            )}
                    </Collapse>

                    {/* Only show the bottom show/hide bar if the section has no title */}
                    {canCollapse && !section.label && (
                        <Box
                            style={{
                                borderTop: `1px solid ${V4DesignSystem.colors.gray[100]}`,
                            }}
                            px={['container.padding', null, null, 'container.paddingLg']}
                            py={[2, null, null, 4]}
                            mt={'-1px'}
                        >
                            <Button
                                variant="clear"
                                size="sm"
                                px={0}
                                onClick={() => {
                                    setState((state) => {
                                        return {
                                            ...state,
                                            collapsedSections: {
                                                ...state.collapsedSections,
                                                [section.id]: !sectionCollapsed,
                                            },
                                        }
                                    })
                                }}
                            >
                                {sectionCollapsed ? (
                                    <>
                                        Show <Icon icon="arrowDown" ml={2} display="inline-block" />
                                    </>
                                ) : (
                                    <>
                                        Hide <Icon icon="arrowUp" ml={2} display="inline-block" />
                                    </>
                                )}
                            </Button>
                        </Box>
                    )}
                </Wrapper>
            </Collapse>
        )
    })

    return sectionComponents
}

const FieldContainerBlock = (props) => {
    const { attrs } = props

    return (
        <WithObject objectId={attrs.objectId}>
            {({ object }) => <InnerFieldContainerBlock object={object} {...props} />}
        </WithObject>
    )
}

export default FieldContainerBlock

FieldContainerBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    // This FieldContainerEditor uses a prop method setOnlyVisible, specified in RenderBlockTypeAttributes
    // to avoid displaying any other attribute (like conditionalVisibility) if we're editing a field
    configuratorWrapper({
        field: 'content',
        Element: FieldContainerEditor,
        simple: true,
        fullHeight: true,
        minHeight: '0px',
        showAllFieldsModeSetting: true,
        showDefaultValueSetting: true,
        showPlaceholderSetting: true,
    }),
]

const FieldContainerTitle = ({
    title,
    description,
    children = null,
    collapsed,
    canCollapse,
    toggleCollapse,
    isMobile,
}) => {
    if (!title && !description && !children) return null

    const titleCannotCollapse = (
        <Heading
            as="h2"
            value={title}
            variant="widgetContainerTitleNoMargin"
            style={{ textAlign: 'start' }}
        />
    )
    const titleCanCollapse = (
        <Flex>
            <Button
                variant="clear"
                p={0}
                onClick={toggleCollapse}
                style={{ whiteSpace: 'break-spaces' }}
                mb={isMobile ? 2 : 0}
            >
                {titleCannotCollapse}
                {collapsed ? (
                    <Icon icon="arrowDown" ml={2} display="inline-block" />
                ) : (
                    <Icon icon="arrowUp" ml={2} display="inline-block" />
                )}
            </Button>
        </Flex>
    )

    return (
        <Flex alignItems={'flex-start'} wrap="nowrap">
            {title || description ? (
                <Flex
                    column
                    align="stretch"
                    flexShrink={2}
                    minWidth="30%"
                    alignContent="flex-start"
                    alignItems="flex-start"
                    flexGrow={1}
                    mr={2}
                >
                    {title && (canCollapse ? titleCanCollapse : titleCannotCollapse)}
                    {description && (
                        <Text variant="widgetContainerDescription" width="100%">
                            <MarkdownText
                                showRichTextEditor={typeof description === 'object'}
                                convertToMarkdown={false}
                            >
                                {description}
                            </MarkdownText>
                        </Text>
                    )}
                </Flex>
            ) : (
                <div className="flex-1" style={{ minWidth: 5 }} />
            )}
            {children}
        </Flex>
    )
}

export function ActionButtonsList({
    buttons,
    record,
    object,
    editing,
    style = '1pa',
    position = 'top',
    renderWrapper = undefined,
    includeFields,
}) {
    const availableButtons = useActionButtons(buttons, record, object, [])
    const maxVisible = 3

    function getStyle(index) {
        if (style === 'primary') return {}

        // first item is primary, others are secondary
        if (style === '1pa' && index === 0) return {}

        // useful if there is a dropdown
        if (index >= maxVisible) return {}

        return {
            variant: 'adminSecondary',
            buttonSize: 'sm',
        }
    }

    return (
        <ActionButtonList
            record={record}
            maxVisible={maxVisible}
            hideMoreItemsDropdown
            getActionProps={({ index }) => getStyle(index)}
            actions={availableButtons.map((item) => {
                return {
                    ...item,
                    mb: 2,
                    isDisabled: editing,
                }
            })}
            renderWrapper={(children) => (
                <Flex
                    flexShrink={1}
                    mb={-2}
                    style={{
                        justifyContent: position === 'top' ? 'flex-end' : 'flex-start',
                        alignItems: 'center',
                        flexDirection: position === 'top' ? 'row-reverse' : 'row',
                    }}
                >
                    {renderWrapper ? renderWrapper(children) : children}
                </Flex>
            )}
            includeFields={includeFields}
        />
    )
}
